<template>
  <div class="container-fluid">
    <div class="row row-cols-1">
      <loading :active.sync="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage">
        <template v-slot:before><span class="loadingText">每一個票價在當下都代表一個意義...</span> </template></loading
      >
      <div class="col-12">
        <div class="row row-cols-1 mt-5">
          <div class="col">
            <div class="card border border-0">
              <div class="card-body">
                <SearchFrom @query="initChart"></SearchFrom>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-cols-1" v-if="!showSection">
          <div class="col">
            <div class="card border border-0">
              <div class="card-body">
                <LazyBag></LazyBag>
              </div>
            </div>
          </div>
        </div>
        <div class="col" v-if="showSection">
          <div class="alert alert-warning" role="alert">
            <ol class="m-0">
              <li class="mb-1">所提供的票價資訊均是建立在「直飛，台灣出發的來回且大人人數是 1 位」的情況下取得</li>
              <li class="mb-1">除非有另外說明，不然票價單位一律都是「單程未稅價，新台幣(TWD)」，票種也一律都是基本票種（也就是不包含任何附加服務項目）</li>
              <li class="mb-1">票價軌跡呈現以提供最近 10 筆為主(未來視情況調整)</li>
              <li class="mb-1">部份業者在外站出發定價上，由於會受匯率及銷售政策影響，對於新台幣轉換上不一定會是等值，還請留意</li>
              <li class="mb-1">本站將盡力提供正確之資訊，惟本網站對於所載資料之完整性、即時性和正確性不做任何擔保</li>
            </ol>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-lg-1 bg-success p-2 text-dark bg-opacity-10 rounded-2" v-show="showSection">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <div id="ch1">
                  <apexchart ref="initChart" height="350px" :options="options1" :series="series" @dataPointSelection="clickHandler1"> </apexchart>
                </div>
                <div id="ch2">
                  <apexchart ref="initChart2" height="200px" :options="options3" :series="series"> </apexchart>
                </div>
                <div id="ch3">
                  <apexchart ref="chart" width="100%" height="350px" :options="options2" :series="options2.series"> </apexchart>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="container-fluid mt-3 bg-light rounded">
              <div class="table-wrap table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="p-0">
                      <td class="w100 p-0 align-middle" scope="col" rowspan="2"><small class="btn btn-primary h-1 px-2">同天其他班次</small><br /><small>*出發、抵達時間皆為24小時制</small></td>
                      <!-- <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                        <b class="text-muted"
                          >空位情況<button type="button" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#helpModal">
                            <i class="bi bi-question-circle"></i></button
                        ></b>
                      </td> -->
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                        <b class="text-muted">票價(單程未稅)</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                        <b class="text-muted">跟上次相比</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col" colspan="2">
                        <b class="text-muted">以紀錄日期來看<br />其平均價格</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col" colspan="3">
                        <b class="text-muted">以旅行日期劃分<br />其平均價格</b>
                      </td>
                    </tr>
                    <tr>
                      <!-- <td class="text-center w100 p-0 py-2" scope="col">
                        <small class="text-muted">過去3天</small>
                      </td> -->
                      <td class="text-center w100 p-0 py-2" scope="col">
                        <small class="text-muted">過去7天</small>
                      </td>
                      <!-- <td class="text-center w100 p-0 py-2" scope="col">
                        <small class="text-muted">過去14天</small>
                      </td> -->
                      <td class="text-center w100 p-0 py-2" scope="col">
                        <small class="text-muted">過去30天</small>
                      </td>
                      <!-- <td class="text-center w100 p-0 py-2" scope="col">
                        <small class="text-muted">跟前一個月相比</small>
                      </td> -->
                      <td class="text-center w100 p-0 py-2" scope="col">
                        <small class="text-muted">早一週出發</small>
                      </td>
                      <td class="text-center w100 p-0 py-2" scope="col">
                        <small class="text-muted">晚一週出發</small>
                      </td>
                      <td class="text-center w100 p-0 py-2" scope="col">
                        <small class="text-muted">全月平均</small>
                      </td>
                    </tr>
                  </thead>
                  <tbody class="table-group-divider">
                    <tr class="border-bottom bg-white" v-for="(item, key) in rows" :key="key">
                      <td class="row text-center">
                        <div class="d-inline-flex align-items-center">
                          <span :class="'bg-' + item.airline + ' mx-2'"></span>
                          <div class="flex-column">
                            <div class="boxItem">
                              <!-- <h5>{{ item.departureDate }}</h5> -->
                              出發 {{ item.departureTime }}
                            </div>
                            <div class="line"></div>
                            <div class="boxItem">
                              抵達 {{ item.arrivalTime }}
                              <small v-if="item.departureDate != item.arrivalDate">(+1)</small>
                            </div>
                            <!-- <h4><i class="bi bi-bookmark-plus"></i></h4> -->
                            <!-- <h5> -->
                            <div style="word-wrap: break-word">
                              {{ item.airlineName }}
                              <span class="fs-4">{{ item.flightNo }}</span>
                            </div>
                            <!-- </h5> -->
                          </div>
                        </div>
                      </td>
                      <!-- <td class="text-center align-middle">
                        <span v-if="item.remainingRateColor == 'danger'" :class="'fs-6 dot-red'"></span>
                        <span v-else-if="item.remainingRateColor == 'warning'" :class="'fs-6 dot-yellow'"></span>
                        <span v-else-if="item.remainingRateColor == 'success'" :class="'fs-6 dot-green'"></span>
                        <span v-else-if="item.remainingRateColor == 'secondary'" :class="'fs-6 dot-secondary'"></span>
                        <span v-else>-</span>
                        <P v-if="item.airline === 'TR' || item.airline === '7C'"
                          ><B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.soldSeat_diff < 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.soldSeat_diff > 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.soldSeat_diff == 0"></i></B>
                          <small v-if="item.soldSeat_diff >= 0">{{ item.soldSeat_diff }} ({{ item.soldRate_diff1 * 100 }} %)</small>
                          <small v-else>{{ item.soldSeat_diff * -1 }} ({{ item.soldRate_diff1 * -1 * 100 }} %)</small>
                        </P>
                      </td> -->
                      <td class="text-center align-middle">
                        <!-- <button
                          type="button"
                          class="btn btn-link fs-5"
                          data-bs-toggle="modal"
                          data-bs-target="#farehistoryModal"
                          :data-bs-item="
                            JSON.stringify({
                              airline: item.airline,
                              dept_d: item.departureDate,
                              dept: item.departureStation,
                              arri: item.arrivalStation,
                              fareAmount: item.fareAmount - 500,
                              avg_7d: item.avg_7d,
                              flight_no: '',
                              unit: 'd',
                              value: '1',
                              currency: item.currency,
                            })
                          ">
                          <i class="bi bi-bell"></i>
                        </button> -->
                        {{ item.currency }}
                        {{ item.fareAmount.toLocaleString() }}
                        <P
                          ><small>{{ item.fetchCreatedAt }}</small>
                          <!-- <div class="fs-6" v-html="showlink(item.airline, item.departureDate, item.departureStation, item.arrivalStation)"></div> -->
                          <div class="fs-6">
                            <button type="button" class="btn btn-link" @click="showConfirm(item.airline, item.departureDate, item.departureStation, item.arrivalStation)"><i class="bi bi-box-arrow-up-right"></i> 前往官網</button>
                          </div>
                        </P>
                      </td>
                      <td class="text-center align-middle">
                        <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fare_trend_diff1 > 0"></i></B>
                        <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fare_trend_diff1 < 0"></i></B>
                        <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fare_trend_diff1 == 0"></i></B>
                        {{ item.fare_trend_diff1.toLocaleString() }}
                        <P
                          ><small>{{ item.timestamp_trend_diff1 }} hours ago</small></P
                        >
                      </td>

                      <!-- <td class="text-center align-middle" v-if="item.avg_3d <= 0">
                        <p>
                          <B><span class="badge text-bg-danger">資料不足</span></B>
                        </p>
                        <p><small>暫時無法提供</small></p>
                      </td>
                      <td class="text-center align-middle" v-if="item.avg_3d > 0">
                        {{ item.currency }} {{ item.avg_3d.toLocaleString() }}
                        <p>
                          <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fareAmount - item.avg_3d > 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fareAmount - item.avg_3d < 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fareAmount - item.avg_3d == 0"></i></B>
                          {{ (item.fareAmount - item.avg_3d).toLocaleString() }}
                        </p>
                      </td> -->

                      <td class="text-center align-middle" v-if="item.avg_7d <= 0">
                        <p>
                          <B><span class="badge text-bg-danger">資料不足</span></B>
                        </p>
                        <p><small>暫時無法提供</small></p>
                      </td>
                      <td class="text-center align-middle" v-if="item.avg_7d > 0">
                        {{ item.currency }} {{ item.avg_7d.toLocaleString() }}
                        <p>
                          <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fareAmount - item.avg_7d > 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fareAmount - item.avg_7d < 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fareAmount - item.avg_7d == 0"></i></B>
                          {{ (item.fareAmount - item.avg_7d).toLocaleString() }}
                        </p>
                      </td>

                      <!-- <td class="text-center align-middle" v-if="item.avg_14d <= 0">
                        <p>
                          <B><span class="badge text-bg-danger">資料不足</span></B>
                        </p>
                        <p><small>暫時無法提供</small></p>
                      </td>
                      <td class="text-center align-middle" v-if="item.avg_14d > 0">
                        {{ item.currency }} {{ item.avg_14d.toLocaleString() }}
                        <p>
                          <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fareAmount - item.avg_14d > 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fareAmount - item.avg_14d < 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fareAmount - item.avg_14d == 0"></i></B>
                          {{ (item.fareAmount - item.avg_14d).toLocaleString() }}
                        </p>
                      </td> -->

                      <td class="text-center align-middle" v-if="item.avg_30d <= 0">
                        <p>
                          <B><span class="badge text-bg-danger">資料不足</span></B>
                        </p>
                        <p><small>暫時無法提供</small></p>
                      </td>
                      <td class="text-center align-middle" v-if="item.avg_30d > 0">
                        {{ item.currency }} {{ item.avg_30d.toLocaleString() }}
                        <p>
                          <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fareAmount - item.avg_30d > 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fareAmount - item.avg_30d < 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fareAmount - item.avg_30d == 0"></i></B>
                          {{ (item.fareAmount - item.avg_30d).toLocaleString() }}
                        </p>
                      </td>

                      <!-- <td class="text-center align-middle" v-if="item.avg_lastMonByQD <= 0">
                        <p>
                          <B><span class="badge text-bg-danger">資料不足</span></B>
                        </p>
                        <p><small>暫時無法提供</small></p>
                      </td>
                      <td class="text-center align-middle" v-if="item.avg_lastMonByQD > 0">
                        {{ item.currency }} {{ item.avg_lastMonByQD.toLocaleString() }}
                        <p>
                          <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fareAmount - item.avg_lastMonByQD > 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fareAmount - item.avg_lastMonByQD < 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fareAmount - item.avg_lastMonByQD == 0"></i></B>
                          {{ (item.fareAmount - item.avg_lastMonByQD).toLocaleString() }}
                        </p>
                      </td> -->

                      <td class="text-center align-middle" v-if="item.avg_lastWeekByDD <= 0">
                        <p>
                          <B><span class="badge text-bg-danger">資料不足</span></B>
                        </p>
                        <p><small>暫時無法提供</small></p>
                      </td>
                      <td class="text-center align-middle" v-if="item.avg_lastWeekByDD > 0">
                        {{ item.currency }}
                        {{ item.avg_lastWeekByDD.toLocaleString() }}
                        <p>
                          <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fareAmount - item.avg_lastWeekByDD > 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fareAmount - item.avg_lastWeekByDD < 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fareAmount - item.avg_lastWeekByDD == 0"></i></B>
                          {{ (item.fareAmount - item.avg_lastWeekByDD).toLocaleString() }}
                        </p>
                      </td>

                      <td class="text-center align-middle" v-if="item.avg_afterWeekByDD <= 0">
                        <p>
                          <B><span class="badge text-bg-danger">資料不足</span></B>
                        </p>
                        <p><small>暫時無法提供</small></p>
                      </td>
                      <td class="text-center align-middle" v-if="item.avg_afterWeekByDD > 0">
                        {{ item.currency }}
                        {{ item.avg_afterWeekByDD.toLocaleString() }}
                        <p>
                          <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fareAmount - item.avg_afterWeekByDD > 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fareAmount - item.avg_afterWeekByDD < 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fareAmount - item.avg_afterWeekByDD == 0"></i></B>
                          {{ (item.fareAmount - item.avg_afterWeekByDD).toLocaleString() }}
                        </p>
                      </td>

                      <td class="text-center align-middle" v-if="item.avg_thisMonByDD <= 0">
                        <p>
                          <B><span class="badge text-bg-danger">資料不足</span></B>
                        </p>
                        <p><small>暫時無法提供</small></p>
                      </td>
                      <td class="text-center align-middle" v-if="item.avg_thisMonByDD > 0">
                        {{ item.currency }}
                        {{ item.avg_thisMonByDD.toLocaleString() }}
                        <p>
                          <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fareAmount - item.avg_thisMonByDD > 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fareAmount - item.avg_thisMonByDD < 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fareAmount - item.avg_thisMonByDD == 0"></i></B>
                          {{ (item.fareAmount - item.avg_thisMonByDD).toLocaleString() }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- <hr class="hrs" /> -->
          <div class="col mt-3" v-if="q.trip_type === 'RT'">
            <div class="card">
              <div class="card-body">
                <div id="ch4">
                  <apexchart ref="initChart3" height="350px" :options="options4" :series="series3" @dataPointSelection="clickHandler2"> </apexchart>
                </div>
                <div id="ch5">
                  <apexchart ref="initChart4" height="200px" :options="options6" :series="series3"> </apexchart>
                </div>
                <div id="ch6">
                  <apexchart ref="chart2" width="100%" height="350px" :options="options5" :series="options5.series"> </apexchart>
                </div>
              </div>
            </div>
          </div>
          <div class="col" v-if="q.trip_type === 'RT'">
            <div class="container-fluid mt-3 bg-light rounded">
              <div class="table-wrap table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="p-0">
                      <td class="w100 p-0 align-middle" scope="col" rowspan="2"><small class="btn btn-primary h-1 px-2">同天其他班次</small><br /><small>*出發、抵達時間皆為24小時制</small></td>
                      <!-- <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                        <b class="text-muted"
                          >空位情況<button type="button" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#helpModal">
                            <i class="bi bi-question-circle"></i></button
                        ></b>
                      </td> -->
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                        <b class="text-muted">票價(單程未稅)</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col" rowspan="2">
                        <b class="text-muted">跟上次相比</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col" colspan="2">
                        <b class="text-muted">以紀錄日期來看<br />其平均價格</b>
                      </td>
                      <td class="text-center w100 p-0 py-2 align-middle" scope="col" colspan="3">
                        <b class="text-muted">以旅行日期劃分<br />其平均價格</b>
                      </td>
                    </tr>
                    <tr>
                      <!-- <td class="text-center w100 p-0 py-2" scope="col">
                        <small class="text-muted">過去3天</small>
                      </td> -->
                      <td class="text-center w100 p-0 py-2" scope="col">
                        <small class="text-muted">過去7天</small>
                      </td>
                      <!-- <td class="text-center w100 p-0 py-2" scope="col">
                        <small class="text-muted">過去14天</small>
                      </td> -->
                      <td class="text-center w100 p-0 py-2" scope="col">
                        <small class="text-muted">過去30天</small>
                      </td>
                      <!-- <td class="text-center w100 p-0 py-2" scope="col">
                        <small class="text-muted">跟前一個月相比</small>
                      </td> -->
                      <td class="text-center w100 p-0 py-2" scope="col">
                        <small class="text-muted">早一週出發</small>
                      </td>
                      <td class="text-center w100 p-0 py-2" scope="col">
                        <small class="text-muted">晚一週出發</small>
                      </td>
                      <td class="text-center w100 p-0 py-2" scope="col">
                        <small class="text-muted">全月平均</small>
                      </td>
                    </tr>
                  </thead>
                  <tbody class="table-group-divider">
                    <tr class="border-bottom bg-white" v-for="(item, key) in rows2" :key="key">
                      <td class="row text-center">
                        <div class="d-inline-flex align-items-center">
                          <span :class="'bg-' + item.airline + ' mx-2'"></span>
                          <div class="flex-column">
                            <div class="boxItem">
                              <!-- <h5>{{ item.departureDate }}</h5> -->
                              出發 {{ item.departureTime }}
                            </div>
                            <div class="line"></div>
                            <div class="boxItem">
                              抵達 {{ item.arrivalTime }}
                              <small v-if="item.departureDate != item.arrivalDate">(+1)</small>
                            </div>
                            <!-- <h4><i class="bi bi-bookmark-plus"></i></h4> -->
                            <!-- <h5> -->
                            <div style="word-wrap: break-word">
                              {{ item.airlineName }}
                              <span class="fs-4">{{ item.flightNo }}</span>
                            </div>
                            <!-- </h5> -->
                          </div>
                        </div>
                      </td>
                      <!-- <td class="text-center align-middle">
                        <span v-if="item.remainingRateColor == 'danger'" :class="'fs-6 dot-red'"></span>
                        <span v-else-if="item.remainingRateColor == 'warning'" :class="'fs-6 dot-yellow'"></span>
                        <span v-else-if="item.remainingRateColor == 'success'" :class="'fs-6 dot-green'"></span>
                        <span v-else-if="item.remainingRateColor == 'secondary'" :class="'fs-6 dot-secondary'"></span>
                        <span v-else>-</span>
                        <P v-if="item.airline === 'TR' || item.airline === '7C'"
                          ><B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.soldSeat_diff < 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.soldSeat_diff > 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.soldSeat_diff == 0"></i></B>
                          <small v-if="item.soldSeat_diff >= 0">{{ item.soldSeat_diff }} ({{ item.soldRate_diff1 * 100 }} %)</small>
                          <small v-else>{{ item.soldSeat_diff * -1 }} ({{ item.soldRate_diff1 * -1 * 100 }} %)</small>
                        </P>
                      </td> -->
                      <td class="text-center align-middle">
                        <!-- <button
                          type="button"
                          class="btn btn-link fs-5"
                          data-bs-toggle="modal"
                          data-bs-target="#farehistoryModal"
                          :data-bs-item="
                            JSON.stringify({
                              airline: item.airline,
                              dept_d: item.departureDate,
                              dept: item.departureStation,
                              arri: item.arrivalStation,
                              fareAmount: item.fareAmount - 500,
                              avg_7d: item.avg_7d,
                              flight_no: '',
                              unit: 'd',
                              value: '1',
                              currency: item.currency,
                            })
                          ">
                          <i class="bi bi-bell"></i>
                        </button> -->
                        {{ item.currency }}
                        {{ item.fareAmount.toLocaleString() }}
                        <P
                          ><small>{{ item.fetchCreatedAt }}</small>
                          <!-- <div class="fs-6" v-html="showlink(item.airline, item.departureDate, item.departureStation, item.arrivalStation)"></div> -->
                          <div class="fs-6">
                            <button type="button" class="btn btn-link" @click="showConfirm(item.airline, item.departureDate, item.departureStation, item.arrivalStation)"><i class="bi bi-box-arrow-up-right"></i> 前往官網</button>
                          </div>
                        </P>
                      </td>
                      <td class="text-center align-middle">
                        <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fare_trend_diff1 > 0"></i></B>
                        <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fare_trend_diff1 < 0"></i></B>
                        <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fare_trend_diff1 == 0"></i></B>
                        {{ item.fare_trend_diff1.toLocaleString() }}
                        <P
                          ><small>{{ item.timestamp_trend_diff1 }} hours ago</small></P
                        >
                      </td>

                      <!-- <td class="text-center align-middle" v-if="item.avg_3d <= 0">
                        <p>
                          <B><span class="badge text-bg-danger">資料不足</span></B>
                        </p>
                        <p><small>暫時無法提供</small></p>
                      </td>
                      <td class="text-center align-middle" v-if="item.avg_3d > 0">
                        {{ item.currency }} {{ item.avg_3d.toLocaleString() }}
                        <p>
                          <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fareAmount - item.avg_3d > 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fareAmount - item.avg_3d < 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fareAmount - item.avg_3d == 0"></i></B>
                          {{ (item.fareAmount - item.avg_3d).toLocaleString() }}
                        </p>
                      </td> -->

                      <td class="text-center align-middle" v-if="item.avg_7d <= 0">
                        <p>
                          <B><span class="badge text-bg-danger">資料不足</span></B>
                        </p>
                        <p><small>暫時無法提供</small></p>
                      </td>
                      <td class="text-center align-middle" v-if="item.avg_7d > 0">
                        {{ item.currency }} {{ item.avg_7d.toLocaleString() }}
                        <p>
                          <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fareAmount - item.avg_7d > 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fareAmount - item.avg_7d < 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fareAmount - item.avg_7d == 0"></i></B>
                          {{ (item.fareAmount - item.avg_7d).toLocaleString() }}
                        </p>
                      </td>

                      <td class="text-center align-middle" v-if="item.avg_14d <= 0">
                        <p>
                          <B><span class="badge text-bg-danger">資料不足</span></B>
                        </p>
                        <p><small>暫時無法提供</small></p>
                      </td>
                      <!-- <td class="text-center align-middle" v-if="item.avg_14d > 0">
                        {{ item.currency }} {{ item.avg_14d.toLocaleString() }}
                        <p>
                          <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fareAmount - item.avg_14d > 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fareAmount - item.avg_14d < 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fareAmount - item.avg_14d == 0"></i></B>
                          {{ (item.fareAmount - item.avg_14d).toLocaleString() }}
                        </p>
                      </td> -->

                      <td class="text-center align-middle" v-if="item.avg_30d <= 0">
                        <p>
                          <B><span class="badge text-bg-danger">資料不足</span></B>
                        </p>
                        <p><small>暫時無法提供</small></p>
                      </td>
                      <td class="text-center align-middle" v-if="item.avg_30d > 0">
                        {{ item.currency }} {{ item.avg_30d.toLocaleString() }}
                        <p>
                          <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fareAmount - item.avg_30d > 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fareAmount - item.avg_30d < 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fareAmount - item.avg_30d == 0"></i></B>
                          {{ (item.fareAmount - item.avg_30d).toLocaleString() }}
                        </p>
                      </td>

                      <!-- <td class="text-center align-middle" v-if="item.avg_lastMonByQD <= 0">
                        <p>
                          <B><span class="badge text-bg-danger">資料不足</span></B>
                        </p>
                        <p><small>暫時無法提供</small></p>
                      </td>
                      <td class="text-center align-middle" v-if="item.avg_lastMonByQD > 0">
                        {{ item.currency }} {{ item.avg_lastMonByQD.toLocaleString() }}
                        <p>
                          <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fareAmount - item.avg_lastMonByQD > 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fareAmount - item.avg_lastMonByQD < 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fareAmount - item.avg_lastMonByQD == 0"></i></B>
                          {{ (item.fareAmount - item.avg_lastMonByQD).toLocaleString() }}
                        </p>
                      </td> -->

                      <td class="text-center align-middle" v-if="item.avg_lastWeekByDD <= 0">
                        <p>
                          <B><span class="badge text-bg-danger">資料不足</span></B>
                        </p>
                        <p><small>暫時無法提供</small></p>
                      </td>
                      <td class="text-center align-middle" v-if="item.avg_lastWeekByDD > 0">
                        {{ item.currency }}
                        {{ item.avg_lastWeekByDD.toLocaleString() }}
                        <p>
                          <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fareAmount - item.avg_lastWeekByDD > 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fareAmount - item.avg_lastWeekByDD < 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fareAmount - item.avg_lastWeekByDD == 0"></i></B>
                          {{ (item.fareAmount - item.avg_lastWeekByDD).toLocaleString() }}
                        </p>
                      </td>

                      <td class="text-center align-middle" v-if="item.avg_afterWeekByDD <= 0">
                        <p>
                          <B><span class="badge text-bg-danger">資料不足</span></B>
                        </p>
                        <p><small>暫時無法提供</small></p>
                      </td>
                      <td class="text-center align-middle" v-if="item.avg_afterWeekByDD > 0">
                        {{ item.currency }}
                        {{ item.avg_afterWeekByDD.toLocaleString() }}
                        <p>
                          <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fareAmount - item.avg_afterWeekByDD > 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fareAmount - item.avg_afterWeekByDD < 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fareAmount - item.avg_afterWeekByDD == 0"></i></B>
                          {{ (item.fareAmount - item.avg_afterWeekByDD).toLocaleString() }}
                        </p>
                      </td>

                      <td class="text-center align-middle" v-if="item.avg_thisMonByDD <= 0">
                        <p>
                          <B><span class="badge text-bg-danger">資料不足</span></B>
                        </p>
                        <p><small>暫時無法提供</small></p>
                      </td>
                      <td class="text-center align-middle" v-if="item.avg_thisMonByDD > 0">
                        {{ item.currency }}
                        {{ item.avg_thisMonByDD.toLocaleString() }}
                        <p>
                          <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000" v-if="item.fareAmount - item.avg_thisMonByDD > 0"></i></B>
                          <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e" v-if="item.fareAmount - item.avg_thisMonByDD < 0"></i></B>
                          <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666" v-if="item.fareAmount - item.avg_thisMonByDD == 0"></i></B>
                          {{ (item.fareAmount - item.avg_thisMonByDD).toLocaleString() }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="liveAlertPlaceholder"></div>

    <div class="modal fade" id="helpModal" tabindex="-1" aria-labelledby="helpModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="helpModalLabel">空位情況</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>目前僅提供 濟州航空(7C)，酷航(TR)</p>
            <p>需要說明地方</p>
            <ul>
              <li>酷航計算上是「經濟艙＋酷航Plus之合計」</li>
              <li>請務必要搭配一旁的資料取得時間一起看</li>
              <li>由於航空公司機位販售不會只針對散客，常見都會先保留一部份數量給旅行業者銷售，所以是會發生一下子無空位或是一下子回流到散客的情況</li>
              <li>無法提供原因，主要是沒有這方面數據</li>
            </ul>
            <p>各燈號所代表意思如下 (僅供參考，很容易受到實際銷售影響)</p>
            <ul class="list-group">
              <li class="list-group-item"><span class="fs-6 dot-green"></span> 超過 60.00% 空位</li>
              <li class="list-group-item"><span class="fs-6 dot-yellow"></span> 介於 30.00 ~ 59.99%</li>
              <li class="list-group-item"><span class="fs-6 dot-red"></span> 低於 30.00%</li>
              <li class="list-group-item"><span class="fs-6 dot-secondary"></span> 暫時無空位</li>
              <li class="list-group-item"><span class="fs-6">-</span> 無法提供</li>
            </ul>
            <hr />
            <p>漲降數字說明，大概有這三種情況，個別舉例如下</p>
            <P>
              <B><i class="bi bi-arrow-down-short" style="font-size: 1.4rem; color: #22c32e"></i></B> <small>3 (1%)</small> 代表最近一次的變動，整體可販售座位數減少了３個，佔總座位數約１%
            </P>
            <P>
              <B><i class="bi bi-arrow-up-short" style="font-size: 1.4rem; color: #ff0000"></i></B> <small>17 (7%)</small> 代表最近一次的變動，整體可販售座數多增加了１７個，佔總座位數約７%
            </P>
            <P>
              <B><i class="bi bi-dash" style="font-size: 1.4rem; color: #666666"></i></B> <small>0 (0%)</small> 代表最近一次的變動，整體可販售座數沒有增加也沒有減少
            </P>
            <P>減少只能說明最近一次變動下，有可能是真的賣出去，又或是者是分配給旅行業者，反之增加也是如此，建議當參考就好</P>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade modal-xl" id="farehistoryModal" tabindex="-1" aria-labelledby="farehistoryModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="helpModalLabel">票價追蹤</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <form @submit.prevent="getFormValues">
                  <fieldset class="row mb-3">
                    <legend class="col-form-label col-sm-2 pt-0">通知模式</legend>
                    <div class="col-sm-10">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="ruleform.mode" type="radio" name="mode" id="mode1" value="now" checked required />
                        <label class="form-check-label" for="mode1"> 目前 </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="ruleform.mode" type="radio" name="mode" id="mode2" value="trend" required />
                        <label class="form-check-label" for="mode2"> 趨勢 </label>
                      </div>
                      <!-- <div class="form-check form-check-inline disabled">
                        <input class="form-check-input" v-model="ruleform.mode" type="radio" name="mode" id="mode3" value="report" disabled />
                        <label class="form-check-label" for="mode3"> 報表 </label>
                      </div> -->
                    </div>
                  </fieldset>
                  <!-- <hr /> -->
                  <fieldset class="row mb-3">
                    <legend class="col-form-label col-sm-2 pt-0">通知方式</legend>
                    <div class="col-sm-10">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="ruleform.fare_base" type="radio" name="fare_base" id="fare_base1" value="under" @click="open_fare(true, 'under')" required />
                        <label class="form-check-label" for="fare_base1"> 低於設定價才通知 </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="ruleform.fare_base" type="radio" name="fare_base" id="fare_base2" value="above" @click="open_fare(true, 'above')" required />
                        <label class="form-check-label" for="fare_base2"> 高於設定價才通知 </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="ruleform.fare_base" type="radio" name="fare_base" id="fare_base3" value="between" @click="open_fare(false, 'between')" required />
                        <label class="form-check-label" for="fare_base3"> 介於設定價之間才通知 </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="ruleform.fare_base" type="radio" name="fare_base" id="fare_base4" value="none" @click="disable_fare" required />
                        <label class="form-check-label" for="fare_base4"> 不管高或低一律通知 </label>
                      </div>
                    </div>
                  </fieldset>
                  <hr />
                  <div class="row mb-3">
                    <label for="inputEmail3" class="col-sm-5 col-form-label">設定價 (單程未稅 TWD)</label>
                    <template v-if="awesome">
                      <div class="col-sm-7">
                        <input type="number" v-model.number="ruleform.fare1" class="form-control" id="fare1" :disabled="isDisabled" required />
                      </div>
                    </template>
                    <template v-else>
                      <div class="input-group col-sm-10">
                        <input type="number" v-model.number="ruleform.fare1" class="form-control" aria-label="fare1" min="1" :disabled="isDisabled" required />
                        <span class="input-group-text">~</span>
                        <input type="number" v-model.number="ruleform.fare2" class="form-control" aria-label="fare2" min="1" @blur="check" :disabled="isDisabled" required />
                      </div>
                    </template>
                  </div>
                  <div class="row mb-3">
                    <label for="inputEmail3" class="col-sm-2 col-form-label">E-mail</label>
                    <div class="col-sm-10">
                      <input type="email" v-model="ruleform.email" class="form-control" id="email" required />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-sm-10 offset-sm-2">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck1" required />
                        <label class="form-check-label" for="gridCheck1"> 我已了解並同意相關服務條款 </label>
                      </div>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-warning float-end">新增/修改</button>
                </form>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="overflow-auto mt-3" style="max-width: 100%; max-height: 325px">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">操作說明</button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          <p class="lead">Just.崩潰（以下稱本站）提供二種票價追蹤模式：目前、趨勢，兩者說明如下</p>
                          <dt class="col-sm-3">目前</dt>
                          <dd class="col-sm-9">會根據最新一次的價格資料去比對</dd>
                          <dt class="col-sm-3">趨勢</dt>
                          <dd class="col-sm-9">會根據最新一次的價格資料去比對前一次價格資料</dd>
                          <hr />
                          <p class="lead">
                            通知方式提供四種規則：低於設定價、高於設定價、介於設定價之間以及不管高或低一律通知，根據所設定的追蹤模式，其意思會有所不同，此外設定價的價格基準為「<span class="text-danger"><b>單程未稅 TWD</b></span
                            >」
                          </p>
                          <p>
                            當追蹤模式選擇「<span class="text-danger"><b>目前</b></span
                            >」時，例如設定價為 2999
                          </p>
                          <ul>
                            <li>
                              低於設定價才通知：也就是最新一筆的票價資料必須「<span class="text-danger"><b>低於</b></span
                              >」 2999，本站才會寄發通知給你
                            </li>
                            <li>
                              高於設定價才通知：也就是最新一筆的票價資料必須「<span class="text-danger"><b>高於</b></span
                              >」 2999，本站才會寄發通知給你
                            </li>
                            <li>
                              介於設定價之間才通知：例如設定價為 3299 至 4299 話，也就是最新一筆的票價資料必須「<span class="text-danger"><b>介於</b></span
                              >」 3299 至 4299 之間，系統才會寄發通知給你
                            </li>
                            <li>不管高或低一律通知：即便價格沒有變動，本站都會寄發通知給你</li>
                          </ul>
                          <hr />
                          <p>
                            當追蹤模式選擇「<span class="text-danger"><b>趨勢</b></span
                            >」時，此時設定價的意思就會是漲降幅的範圍，例如設定價 1500
                          </p>
                          <ul>
                            <li>
                              低於設定價才通知：也就是最新一筆的票價資料跟前一次的價格比對後，必須「<span class="text-danger"><b>低於</b></span
                              >」 1500，本站才會寄發通知給你
                            </li>
                            <li>
                              高於設定價才通知：也就是最新一筆的票價資料跟前一次的價格比對後，必須「<span class="text-danger"><b>高於</b></span
                              >」 1500，本站才會寄發通知給你
                            </li>
                            <li>
                              介於設定價之間才通知：例如設定價為 1500 至 2500 話，也就是最新一筆的票價資料跟前一次的價格比對後，必須「<span class="text-danger"><b>介於</b></span
                              >」 1500 至 2500 之間，系統才會寄發通知給你
                            </li>
                            <li>不管高或低一律通知：不管漲幅、降幅多少甚或沒變動，本站都會寄發通知給你</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- {{ modal }} -->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
          </div>
        </div>
      </div>
    </div>
    <div class="sticky-bottom" v-if="showPrivacyPolicy">
      <div class="alert alert-dark alert-dismissible" role="alert">
        <div>
          <p class="fs-6">我們使用 cookie 分析和其他追踪技術為您提供更好的用戶體驗。通過繼續使用本站，您確認並同意我們的隱私保護政策。更多關於隱私保護政策，請參考 <router-link to="/PrivacyPolicy">隱私權政策</router-link>。</p>
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="disable_PrivacyPolicy"></button>
      </div>
    </div>
  </div>
</template>
<style>
  @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
  @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
  /* @import url("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.3.0/css/fontawesome.min.css"); */
</style>
<style lang="css" src="@/css/a.css" scoped></style>
<script>
  import SearchFrom from "./SearchFrom.vue"
  import axios from "axios"
  import Loading from "vue-loading-overlay"
  import moment from "moment"
  import "vue-loading-overlay/dist/vue-loading.css"
  import Cookies from "js-cookie"

  export default {
    name: "VueChart",
    components: {
      LazyBag: () => import("./LazyBag.vue"),
      // SponsorTable: () => import("./SponsorTable.vue"),
      // LowFare: () => import("./LowFare.vue"),
      SearchFrom,
      Loading,
      // MonthlyTable: () => import("./MonthlyTable.vue"),
      // CompareMonthlyTable: () => import("./CompareMonthlyTable.vue"),
    },
    mounted: async function () {
      this.doAjax()
      if (Cookies.get("disable_PrivacyPolicy")) {
        this.showPrivacyPolicy = false
      }

      const farehistoryModal = document.getElementById("farehistoryModal")
      if (farehistoryModal) {
        farehistoryModal.addEventListener("show.bs.modal", (event) => {
          const button = event.relatedTarget

          const me = this

          const item = button.getAttribute("data-bs-item")
          const d = JSON.parse(item)
          const fareAmount = d.fareAmount,
            airline = d.airline,
            dept_d = d.dept_d,
            avg_7d = d.avg_7d,
            dept = d.dept,
            arri = d.arri,
            unit = d.unit,
            value = d.value,
            currency = d.currency,
            flight_no = d.flight_no

          if (me.ruleform.fare_base === "between") {
            me.ruleform.fare1 = parseInt(fareAmount)
            me.ruleform.fare2 = parseInt(avg_7d)
          } else {
            me.ruleform.fare1 = parseInt(fareAmount)
            me.ruleform.fare2 = null
          }

          me.ruleform.airline = airline
          me.ruleform.dept_d = dept_d
          me.ruleform.arri_d = null
          me.ruleform.dept = dept
          me.ruleform.arri = arri
          me.ruleform.travel_type = "OW"
          me.ruleform.unit = unit
          me.ruleform.value = value
          me.ruleform.currency = currency
          me.ruleform.flight_no = flight_no
        })
      }
    },
    data: function () {
      return {
        showPrivacyPolicy: true,
        isDisabled: false,
        awesome: true,
        // modal: {
        //   dept_d: "a",
        //   flight_no: "b",
        //   item: "",
        // },
        isLoading: false,
        fullPage: true,
        showCreateSection: false,
        q: {
          dept: "",
          arri: "",
          trip_type: "",
        },
        ruleform: {
          airline: "",
          mode: "now",
          fare_base: "under",
          fare1: 0,
          fare2: null,
          email: "",
          dept: "",
          arri: null,
          dept_d: "",
          arri_d: null,
          travel_type: "OW",
        },
        rows: [],
        rows2: [],
        seen4: false,
        seen3: false,
        // seen2: false,
        seen: false,
        series: [],
        series3: [],
        series_res: [],
        series_res2: [],
        // apiUrl: "https://justbk-api-4cqv3jfjdq-de.a.run.app/",
        // apiUrl: "http://localhost:8000/",
        // apiUrl2: "http://localhost:8011/",

        options3: {
          chart: {
            id: "vuechart-example3",
            height: 250,
            width: "100%",
            stacked: false,
            zoom: {
              type: "x",
              enabled: true,
              autoScaleYaxis: true,
            },
            grid: {
              padding: {
                left: 40,
                right: 20,
              },
            },
            brush: {
              enabled: true,
              target: "vuechart-example1",
            },
            selection: {
              enabled: true,
              fill: {
                color: "#ccc",
                opacity: 0.4,
              },
              stroke: {
                color: "#0D47A1",
              },
            },
          },
          stroke: {
            width: 1,
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: [0, 1],
            formatter: function (value) {
              if (value != 0) return value
            },
          },
          labels: [],
          xaxis: {
            type: "datetime",
            // tickPlacement: "on",
            title: {
              text: "旅行日期",
              // offsetX: 440,
              offsetY: 75,
            },
          },
          yaxis: {
            opposite: true,
            title: {
              text: "漲降幅",
            },
          },
          noData: {
            text: "Loading...",
          },
          // responsive: [
          //   {
          //     breakpoint: 1000,
          //     options: {
          //       chart: {
          //         selection: {
          //           enabled: true,
          //           xaxis: {
          //             min: new Date("2023-08-01").getTime(),
          //             max: new Date("2023-08-10").getTime(),
          //           },
          //           fill: {
          //             color: "#ccc",
          //             opacity: 0.4,
          //           },
          //           stroke: {
          //             color: "#0D47A1",
          //           },
          //         },
          //       },
          //     },
          //   },
          // ],
        },
        options6: {
          chart: {
            id: "vuechart-example6",
            height: 250,
            width: "100%",
            stacked: false,
            zoom: {
              type: "x",
              enabled: true,
              autoScaleYaxis: true,
            },
            brush: {
              enabled: true,
              target: "vuechart-example4",
            },
            selection: {
              enabled: true,
              fill: {
                color: "#ccc",
                opacity: 0.4,
              },
              stroke: {
                color: "#0D47A1",
              },
            },
          },
          grid: {
            padding: {
              left: 40,
              right: 20,
            },
          },
          stroke: {
            width: 1,
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: [0, 1],
            formatter: function (value) {
              if (value != 0) return value
            },
          },
          labels: [],
          xaxis: {
            type: "datetime",
            // tickPlacement: "on",
            title: {
              text: "旅行日期",
              // offsetX: 440,
              offsetY: 75,
            },
          },
          yaxis: [
            {
              opposite: true,
              title: {
                text: "漲降幅",
              },
            },
          ],
          noData: {
            text: "Loading...",
          },
        },
        options1: {
          chart: {
            id: "vuechart-example1",
            height: 250,
            width: "100%",
            stacked: false,
            zoom: {
              type: "x",
              enabled: false,
              autoScaleYaxis: true,
            },
          },
          grid: {
            padding: {
              left: 40,
              right: 20,
            },
          },
          stroke: {
            colors: ["transparent"],
            width: 5,
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: [0, 1],
            // offsetY: 20
          },
          labels: [],
          xaxis: {
            type: "datetime",
            // tickPlacement: "on",
            title: {
              text: "旅行日期",
              // offsetX: 440,
              offsetY: 75,
            },
          },
          yaxis: {
            opposite: true,
            title: {
              text: "票價(單程未稅)",
            },
            tickAmount: 3,
          },
          noData: {
            text: "Loading...",
          },
          tooltip: {
            shared: false,
          },
        },
        options4: {
          chart: {
            id: "vuechart-example4",
            height: 250,
            width: "100%",
            stacked: false,
            zoom: {
              type: "x",
              enabled: false,
              autoScaleYaxis: true,
            },
          },
          grid: {
            padding: {
              left: 40,
              right: 20,
            },
          },
          stroke: {
            colors: ["transparent"],
            width: 5,
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: [0, 1],
            // offsetY: 20
          },
          labels: [],
          xaxis: {
            type: "datetime",
            // tickPlacement: "on",
            title: {
              text: "旅行日期",
              // offsetX: 440,
              offsetY: 75,
            },
          },
          yaxis: {
            opposite: true,
            title: {
              text: "票價(單程未稅)",
            },
            tickAmount: 3,
          },
          noData: {
            text: "Loading...",
          },
          tooltip: {
            shared: false,
          },
        },
        options2: {
          tooltip: {
            x: {
              format: "yyyy-MM-dd HH:mm",
              formatter: function (val) {
                return new Date(val).toString()
              },
            },
          },
          series: [],
          chart: {
            stacked: false,
            // group: 'airline',
            height: 250,
            width: "100%",
            type: "line",
            dropShadow: {
              enabled: true,
              color: "#000",
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            // redrawOnParentResize: false,
            // redrawOnWindowResize: false,
          },
          fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
              inverseColors: false,
              shade: "light",
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100],
            },
          },
          stroke: {
            width: 4,
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1],
          },
          xaxis: {
            type: "datetime",
            title: {
              text: "紀錄日期",
              // offsetX: 440,
              offsetY: 68,
            },
          },
          yaxis: [
            {
              opposite: true,
              title: {
                text: "票價(單程未稅)",
              },
              tickAmount: 6,
            },
            {
              title: {
                text: "張數",
              },
            },
          ],
          noData: {
            text: "請從整月走勢點選想查詢之日期",
            style: {
              fontSize: "18px",
            },
          },
          responsive: [
            {
              breakpoint: 1000,
              options: {
                title: {
                  style: {
                    fontSize: "12px",
                  },
                },
              },
            },
          ],
        },
        options5: {
          tooltip: {
            x: {
              format: "yyyy-MM-dd HH:mm",
              formatter: function (val) {
                return new Date(val).toString()
              },
            },
          },
          series: [],
          chart: {
            stacked: false,
            // group: 'airline',
            height: 250,
            width: "100%",
            type: "line",
            dropShadow: {
              enabled: true,
              color: "#000",
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            // redrawOnParentResize: false,
            // redrawOnWindowResize: false,
          },
          fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
              inverseColors: false,
              shade: "light",
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100],
            },
          },
          stroke: {
            width: 4,
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1],
          },
          xaxis: {
            type: "datetime",
            title: {
              text: "紀錄日期",
              // offsetX: 440,
              offsetY: 68,
            },
          },
          yaxis: [
            {
              opposite: true,
              title: {
                text: "票價(單程未稅)",
              },
              tickAmount: 6,
            },
            {
              title: {
                text: "張數",
              },
            },
          ],
          noData: {
            text: "請從整月走勢點選想查詢之日期",
            style: {
              fontSize: "18px",
            },
          },
          responsive: [
            {
              breakpoint: 1000,
              options: {
                title: {
                  style: {
                    fontSize: "12px",
                  },
                },
              },
            },
          ],
        },
      }
    },
    computed: {
      showSection() {
        return this.showCreateSection
      },
    },
    methods: {
      disable_PrivacyPolicy() {
        Cookies.set("disable_PrivacyPolicy", true, { expires: 365, secure: true })
      },
      showConfirm(airline, departureDate, departureStation, arrivalStation) {
        this.$confirm("由於資料具有時效性關係，實際價格以各航空公司官方訂位系統上所查詢為準").then(async () => {
          // let affiliateChannel = "justbengkueime",
          // promoCode = ""
          // if (airline === "IT") {
          //   const res = await axios.get(`${this.$apiUrl2}get_code?airline=${airline}`)
          //   affiliateChannel = res.data.data[airline]
          //   // promoCode = ""
          // }
          let newDepartureDate = moment(departureDate).format("YYYYMMDD")
          const url = `${this.$webUrl}go?ref=${airline}-${departureStation}${arrivalStation}-${newDepartureDate}&s=1`
          // const url = this.showlink(airline, departureDate, departureStation, arrivalStation, affiliateChannel, promoCode, "text")
          window.open(url, "_blank")
        })
      },
      // showlink(airline, departureDate, departureStation, arrivalStation, affiliateChannel, promoCode, show_type = "html") {
      //   let booking_url = ""
      //   switch (airline) {
      //     case "LJ": {
      //       const newDepartureDate = moment(departureDate).format("YYYYMMDD")
      //       booking_url = `https://www.jinair.com/booking/itinerary/index?arrcity1=${arrivalStation}&tripType=OW&depcity1=${departureStation}&tripday1=N&depdate1=${newDepartureDate}`
      //       break
      //     }
      //     case "7C": {
      //       const availSearchData = { agentId: "deep_everymundo", cultureCode: "zh-TW", includeTaxes: true, paxTypeCount: { adultCount: "1", childCount: "0", infantCount: "0" }, routeType: "I", tripRoutes: [{ departureDate: departureDate, destination: arrivalStation, fareBasisCode: "", flightNumber: [""], origin: departureStation }], tripType: "OW", promotionCode: "" }

      //       const query_str = encodeURIComponent(JSON.stringify(availSearchData))
      //       booking_url = `https://www.jejuair.net/zh-tw/ibe/booking/AvailSearch.do?availSearchData=${query_str}`
      //       break
      //     }
      //     case "IT":
      //       booking_url = `https://booking.tigerairtw.com/?type=oneWay&outbound=${departureStation}-${arrivalStation}&departureDate=${departureDate}&adult=1&children=0&infant=0&languageCode=zh-tw&affiliateChannel=${affiliateChannel}&affiliateCode=chart-${departureStation}${arrivalStation}-${departureDate}&promotionCode=${promoCode}`
      //       break
      //     case "TR":
      //       booking_url = `https://booking.flyscoot.com/Book/Flight?culture=zh-TW&isBeta&type=oneway&dst1=${departureStation}&ast1=${arrivalStation}&dst2=${arrivalStation}&ast2=${departureStation}&dd=${departureDate}&rd=null&adt=1&chd=0&inf=0`
      //       break
      //     case "MM": {
      //       const s = [
      //         {
      //           departure_date: departureDate,
      //           departure_airport_code: departureStation,
      //           arrival_airport_code: arrivalStation,
      //           is_return: false,
      //           return_date: "",
      //         },
      //       ]
      //       const query_str = encodeURIComponent(JSON.stringify(s))
      //       booking_url = `https://booking.flypeach.com/tw/getsearch?s=${query_str}`
      //       break
      //     }
      //     // https://www.vietjetair.com/?departAirport=RMQ&arrivalAirport=SGN&languageCode=zh-tw&departDate=2024-05-31&tripType=oneway&adultCount=1&refCode=9015&companyKey=IEJityRw6703cYsRZzXypmXbrNIXce9eMehD%C6%92BXrPao=&itm_source=Leadr&itm_medium=Landing_Page
      //     case "VZ":
      //       booking_url = `https://www.vietjetair.com/?departAirport=${departureStation}&arrivalAirport=${arrivalStation}&languageCode=zh-tw&departDate=${departureDate}&tripType=oneway&adultCount=1`
      //       break

      //     case "VJ":
      //       booking_url = `https://www.vietjetair.com/?departAirport=${departureStation}&arrivalAirport=${arrivalStation}&languageCode=zh-tw&departDate=${departureDate}&tripType=oneway&adultCount=1`
      //       break

      //     case "FD": {
      //       const newDepartureDate = moment(departureDate).format("DD/MM/YYYY")
      //       booking_url = `https://www.airasia.com/flights/search/?origin=${departureStation}&destination=${arrivalStation}&departDate=${newDepartureDate}&tripType=O&adult=1&child=0&infant=0&locale=zh-tw&currency=TWD&type=bundled&providers=&taIDs=`
      //       break
      //       // https://www.airasia.com/flights/search/?origin=${departureStation}&destination=NRT&departDate=16/06/2024&tripType=O&adult=1&child=0&infant=0&locale=en-gb&currency=TWD&type=bundled&providers=&taIDs=&locale=zh-tw
      //     }

      //     case "D7": {
      //       const newDepartureDate = moment(departureDate).format("DD/MM/YYYY")
      //       booking_url = `https://www.airasia.com/flights/search/?origin=${departureStation}&destination=${arrivalStation}&departDate=${newDepartureDate}&tripType=O&adult=1&child=0&infant=0&locale=zh-tw&currency=TWD&type=bundled&providers=&taIDs=`
      //       break
      //       // https://www.airasia.com/flights/search/?origin=${departureStation}&destination=NRT&departDate=16/06/2024&tripType=O&adult=1&child=0&infant=0&locale=en-gb&currency=TWD&type=bundled&providers=&taIDs=&locale=zh-tw
      //     }
      //     default:
      //       break
      //   }
      //   if (show_type === "html") {
      //     return `<a href="${booking_url}" class="btn btn-link" target="_blank"><i class="bi bi-box-arrow-up-right"></i> 前往官網</a>`
      //   }
      //   if (show_type === "text") {
      //     return booking_url
      //   }
      // },
      open_fare(flag, mode) {
        this.awesome = flag
        if (mode !== "between") {
          //   if (me.ruleform.fare_base !== "between") {
          //     me.ruleform.fare1 = fareAmount
          //     me.ruleform.fare2 = avg_7d
          //   } else {
          //     me.ruleform.fare1 = fareAmount
          //     me.ruleform.fare2 = ""
          //   }
        }
        this.isDisabled = false
      },
      disable_fare() {
        this.ruleform.fare1 = null
        this.ruleform.fare2 = null
        this.isDisabled = true
      },
      check() {
        const fare1 = this.ruleform.fare1
        const fare2 = this.ruleform.fare2
        if (fare2 < fare1) {
          // alert("後面的設定價必須大於前面設定價")
          this.$alert("後面的設定價必須大於前面設定價", "系統訊息", "error")
        }
      },
      async getFormValues(e) {
        e.preventDefault()
        // alert("Email: " + this.ruleform.mode + " " + "Password: " + this.ruleform.fare_base + this.ruleform.fare1)
        const me = this

        axios({
          method: "PATCH",
          url: `${me.$apiUrl2}addEmailRule`,
          headers: {
            Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            "Content-Type": "application/json",
          },
          data: {
            ruleform: this.ruleform,
          },
        }).then(function (response) {
          // console.log(response.data)
          const res = response.data
          if (res.status === 503) {
            me.$alert("新增/修改失敗", "系統訊息", "error")
          }

          if (res.status === 201) {
            if (res.statusText === "Created") {
              me.$alert("新增成功", "系統訊息", "success")
            }
            if (res.statusText === "Updated") {
              me.$alert("修改成功", "系統訊息", "warning")
            }
          }
        })
      },
      doAjax() {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        }, 4500)
      },
      onCancel() {},
      initChart(val) {
        this.showCreateSection = true
        this.seen3 = false
        const query_d = val.dateRange2
        const dept = val.countryName1.split("|")[1]
        const arri = val.cityName1.split("|")[1]
        const trip_type = val.selectedItems1

        const me = this
        me.q.dept = dept
        me.q.arri = arri
        me.q.trip_type = trip_type

        let qurl = "",
          dept_d,
          arri_d

        if (trip_type === "OW") {
          dept_d = query_d
          arri_d = query_d
          qurl = `${this.$apiUrl}${trip_type}/${dept}/${arri}/${dept_d}/`
        }
        if (trip_type === "RT") {
          dept_d = query_d[0]
          arri_d = query_d[1]
          qurl = `${this.$apiUrl}${trip_type}/${dept}/${arri}/${dept_d}|${arri_d}/`
        }
        axios({
          method: "GET",
          url: qurl,
          headers: {
            Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
          },
        }).then(function (response) {
          me.series_res = response.data[0][0]
          me.series_res2 = response.data[1][0]

          let res1 = [],
            res2 = [],
            res3 = [],
            res4 = [],
            detpt_f,
            arri_f
          detpt_f = me.series_res[0][0][0][0].flightNo
          if (trip_type === "RT") {
            arri_f = me.series_res2[0][0][0][0].flightNo
          }

          for (let i = 0; i < response.data[0][0].length; i++) {
            const e = response.data[0][0][i]
            let json_obj1 = {
              name: e[0][0][0].flightNo,
              type: "bar",
              data: e[0][0],
            }
            res1.push(json_obj1)

            let json_obj2 = {
              name: e[0][0][0].flightNo,
              type: "bar",
              data: e[0][1],
            }
            res2.push(json_obj2)
          }

          me.$refs.initChart.updateSeries(res1)
          me.$refs.initChart2.updateSeries(res2)
          me.updateChart(dept_d, detpt_f, "chart")
          me.$refs.initChart.removeAnnotation("my-annotation1")

          if (trip_type === "RT") {
            for (let i = 0; i < response.data[1][0].length; i++) {
              const e = response.data[1][0][i]
              let json_obj1 = {
                name: e[0][0][0].flightNo,
                type: "bar",
                data: e[0][0],
              }
              res3.push(json_obj1)

              let json_obj2 = {
                name: e[0][0][0].flightNo,
                type: "bar",
                data: e[0][1],
              }
              res4.push(json_obj2)
            }

            me.$refs.initChart3.updateSeries(res3)
            me.$refs.initChart4.updateSeries(res4)
            me.updateChart(arri_d, arri_f, "chart2")
            me.$refs.initChart3.removeAnnotation("my-annotation2")
          }

          const iindex_L0 = response.data[0][1].length,
            iindex0 = response.data[0][1].indexOf(dept_d)

          let ix = 0,
            iy = 0
          if (iindex_L0 < 10 || iindex0 < 0) {
            ix = 0
            iy = iindex_L0 - 1
          } else {
            if (iindex0 >= 25) {
              if (iindex0 >= 28) {
                ix = iindex0 - 6
                iy = iindex_L0 - 1
              } else {
                ix = iindex0 - 4
                iy = iindex0 + 2
              }
            } else {
              if (iindex0 <= 1) {
                ix = iindex0
                iy = iindex0 + 5
              } else {
                ix = iindex0 - 2
                iy = iindex0 + 4
              }
            }
          }

          const d1 = response.data[0][1][ix]
          const d2 = response.data[0][1][iy]

          let d3, d4
          if (trip_type === "RT") {
            const iindex_L1 = response.data[1][1].length,
              iindex1 = response.data[1][1].indexOf(arri_d)

            let ix1 = 0,
              iy1 = 0
            if (iindex_L1 < 10 || iindex1 < 0) {
              ix1 = 0
              iy1 = iindex_L1 - 1
            } else {
              if (iindex1 >= 25) {
                if (iindex1 >= 28) {
                  ix1 = iindex1 - 6
                  iy1 = iindex_L1 - 1
                } else {
                  ix1 = iindex1 - 4
                  iy1 = iindex1 + 2
                }
              } else {
                if (iindex1 <= 1) {
                  ix1 = iindex1
                  iy = iindex1 + 5
                } else {
                  ix1 = iindex1 - 2
                  iy1 = iindex1 + 4
                }
              }
            }
            d3 = response.data[1][1][ix1]
            d4 = response.data[1][1][iy1]
          }

          me.options1 = {
            ...me.options1,
            ...{
              labels: response.data[0][1],
              colors: response.data[0][2],
              legend: {
                itemMargin: {
                  vertical: 15,
                },
              },
              title: {
                text: `${dept} 飛往 ${arri} 整月價格走勢`,
                style: {
                  fontSize: "20px",
                },
              },
              // subtitle: {
              //   text: "所提供的票價資訊均是建立在「台灣出發的來回且大人人數是 1 位」的情況",
              // },
            },
          }

          me.options3 = {
            ...me.options3,
            ...{
              // labels: response.data[2]
              chart: {
                brush: {
                  enabled: true,
                  target: "vuechart-example1",
                },
                selection: {
                  enabled: true,
                  xaxis: {
                    min: new Date(d1).getTime(),
                    max: new Date(d2).getTime(),
                  },
                  fill: {
                    color: "#ccc",
                    opacity: 0.4,
                  },
                  stroke: {
                    color: "#0D47A1",
                  },
                },
              },
              colors: response.data[0][2],
              legend: {
                itemMargin: {
                  vertical: 15,
                },
              },
            },
          }

          if (trip_type === "RT") {
            me.options4 = {
              ...me.options4,
              ...{
                labels: response.data[1][1],
                colors: response.data[1][2],
                legend: {
                  itemMargin: {
                    vertical: 15,
                  },
                },
                title: {
                  text: `${arri} 飛往 ${dept} 整月價格走勢`,
                  style: {
                    fontSize: "20px",
                  },
                },
                // subtitle: {
                //   text: "均是建立在「台灣出發的來回且大人人數是 1 位」的情況",
                // },
              },
            }

            me.options6 = {
              ...me.options6,
              ...{
                // labels: response.data[2]
                chart: {
                  brush: {
                    enabled: true,
                    target: "vuechart-example4",
                  },
                  selection: {
                    enabled: true,
                    xaxis: {
                      min: new Date(d3).getTime(),
                      max: new Date(d4).getTime(),
                    },
                    fill: {
                      color: "#ccc",
                      opacity: 0.4,
                    },
                    stroke: {
                      color: "#0D47A1",
                    },
                  },
                },
                colors: response.data[1][2],
                legend: {
                  itemMargin: {
                    vertical: 15,
                  },
                },
              },
            }
          }

          // me.$refs.initChart.toggleDataPointSelection(0, iindex)
          // me.$refs.initChart2.toggleDataPointSelection(0, iindex)
          me.$refs.initChart.addXaxisAnnotation({
            id: "my-annotation1",
            x: new Date(dept_d).getTime(),
            label: {
              text: "出發日",
              // textAnchor: 'start',
              orientation: "horizontal",
              style: {
                fontSize: "14px",
              },
            },
            // points
          })
          if (trip_type === "RT") {
            me.$refs.initChart3.addXaxisAnnotation({
              id: "my-annotation2",
              x: new Date(arri_d).getTime(),
              label: {
                text: "回程日",
                // textAnchor: 'start',
                orientation: "horizontal",
                style: {
                  fontSize: "14px",
                },
              },
            })
          }

          me.seen = false
        })
      },
      updateChart(query_d, flightNo, departureStation, arrivalStation, chartA) {
        const me = this
        const $refs = me.$refs[chartA]

        axios({
          method: "GET",
          url: `${this.$apiUrl}${query_d}/${flightNo}/${departureStation}/${arrivalStation}`,
          headers: {
            Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
          },
        }).then(function (response) {
          $refs.updateSeries([
            {
              name: response.data[0][0].fareTypeText,
              type: "line",
              data: response.data[0],
            },
            {
              name: "張數",
              type: "bar",
              data: response.data[1],
            },
          ])
          $refs.clearAnnotations()
          for (let i = 0; i < response.data[2].length; i++) {
            if (response.data[2][i] !== null) {
              // me.$refs.chart.clearAnnotations()
              const e = response.data[2][i]
              $refs.addXaxisAnnotation({
                x: new Date(e.tagDate).getTime(),
                label: {
                  text: e.tagName,
                  // textAnchor: 'start',
                  orientation: "horizontal",
                  style: {
                    fontSize: "14px",
                  },
                },
              })
            }
          }

          $refs.updateOptions({
            title: {
              text: `${flightNo} 於 ${query_d} 近10筆軌跡`,
              style: {
                fontSize: "20px",
              },
            },
            // subtitle: {
            //   text: "如沒有特別標註，票種一律都是基本票種（也就是不包含附加服務項目的）",
            // },
          })
        })

        const dept = me.q.dept
        const arri = me.q.arri
        const config = {
          headers: { Authorization: `Bearer WHEQs8dl34QW5cYiYORU` },
        }
        if (chartA === "chart") {
          axios.get(`${this.$apiUrl}trends/${query_d}/${dept}/${arri}`, config).then((response) => {
            me.rows = response.data
          })
        }

        if (chartA === "chart2") {
          axios.get(`${this.$apiUrl}trends/${query_d}/${arri}/${dept}`, config).then((response) => {
            me.rows2 = response.data
          })
        }
        this.seen = true
      },
      clickHandler1(event, chartContext, config) {
        const seriesIndex = config.seriesIndex
        const dataPointIndex = config.dataPointIndex
        const query_d = this.series_res[seriesIndex][0][0][dataPointIndex].x
        const departureStation = this.series_res[seriesIndex][0][0][dataPointIndex].departureStation
        const arrivalStation = this.series_res[seriesIndex][0][0][dataPointIndex].arrivalStation
        const flightNo = this.series_res[seriesIndex][0][0][dataPointIndex].flightNo
        this.doAjax()
        this.updateChart(query_d, flightNo, departureStation, arrivalStation, "chart")
      },
      clickHandler2(event, chartContext, config) {
        const seriesIndex = config.seriesIndex
        const dataPointIndex = config.dataPointIndex
        const query_d = this.series_res2[seriesIndex][0][0][dataPointIndex].x
        const departureStation = this.series_res[seriesIndex][0][0][dataPointIndex].departureStation
        const arrivalStation = this.series_res[seriesIndex][0][0][dataPointIndex].arrivalStation
        const flightNo = this.series_res2[seriesIndex][0][0][dataPointIndex].flightNo
        this.doAjax()
        this.updateChart(query_d, flightNo, arrivalStation, departureStation, "chart2")
      },
    },
  }
</script>

<style></style>
