<template>
  <div class="w-auto">
    <date-picker v-model="time3" :placeholder="placeHolder" valueType="format" :range="rang" @close="close" :input-class="inputClass" :popup-style="{ top: '100%', left: 0 }" :append-to-body="false" :disabled-date="disabledBeforeTodayAndAfterAWeek" :input-attr="attr"></date-picker>
  </div>
</template>
<script>
  import DatePicker from "vue2-datepicker"
  import "vue2-datepicker/index.css"
  DatePicker.methods.displayPopup = function () {
    this.position = {
      left: 0,
      bottom: "100%",
    }
  }
  export default {
    components: { DatePicker },
    data() {
      return {
        time3: null,
        placeHolder: "去程日",
        rang: false,
        inputClass: "form-control",
        attr: { required: "true" },
      }
    },
    mounted() {
      // this.time3 = this.formatDate(new Date())
      // this.time3 = "2024-02-05"
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      today.setDate(today.getDate() + 14)
      this.time3 = this.formatDate(today)
      this.$emit("input", this.time3)
    },
    props: ["accept"],
    methods: {
      close() {
        this.$emit("input", this.time3)
      },
      disabledBeforeTodayAndAfterAWeek(date) {
        const today = new Date(this.formatDate(new Date()))
        today.setHours(0, 0, 0, 0)

        return date < today || date > new Date("2025-03-29")
      },
      formatDate(date = new Date()) {
        const year = date.toLocaleString("en", { year: "numeric" })
        const month = date.toLocaleString("en", { month: "2-digit" })
        const day = date.toLocaleString("en", { day: "2-digit" })
        return [year, month, day].join("-")
      },
    },
    watch: {
      accept: function (newA) {
        if (newA === "OW") {
          this.rang = false
          this.placeHolder = "去程日"
        }
        if (newA === "RT") {
          this.rang = true
          this.placeHolder = "去程日 ~ 回程日"
        }
      },
    },
  }
</script>

<style>
  .mx-datepicker {
    width: 100%;
  }

  .mx-datepicker-range {
    width: 100%;
  }

  .mx-icon-double-right,
  .mx-icon-double-left {
    display: none;
  }
</style>
