<template>
  <div id="app">
    <div>
      <VueChart></VueChart>
    </div>
  </div>
</template>

<script>
  // import TList from "./components/TList.vue"
  import VueChart from "./components/MainPage.vue"
  // import VueChart from "./components/MonthlyTable.vue"

  export default {
    name: "App",
    components: {
      // TList,
      VueChart,
    },
    metaInfo: {
      // title: '揣(tshuē) 清艙 - 計畫旅遊票|團體湊票|計劃旅行機票|計劃優惠票',
      title: "Just.崩潰 - 機票票價歷史價格記錄服務",
      htmlAttrs: {
        lang: "zh-TW",
        amp: true,
      },
      meta: [
        // { property: "og:title", content: "Just.崩潰 - 機票票價歷史價格記錄服務" },
        { property: "og:type", content: "website" },
        //   { property: "og:url", content: "https://just.bengkuei.me/" },
        { property: "og:description", content: "「每一個票價在當下都代表一個意義」，Just.崩潰 提供便捷的查詢方式，搭配視覺化圖表呈現，可以快速瞭解過去機票價格情形，目前以有經營台灣往返東北亞（日韓），澳門、東南亞（泰越）航線的低成本航空(LCC) 業者為主（台灣虎航、樂桃航空、酷航、釜山航空、濟州航空、德威航空、真航空、泰國獅子航空、泰國越捷航空、馬來西亞峇迪航空、泰國亞洲航空及越捷航空）。" },
        { property: "og:image", content: "https://i.imgur.com/RlYfLLx.jpeg" },
      ],
    },
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    color: #2c3e50;
    margin-top: 23px;
  }

  /* .logo {
  width: 20%;
} */
</style>
