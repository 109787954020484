<template>
  <div class="card shadow mb-4 bg-white rounded">
    <div class="card-body cd1">
      <div class="alert alert-info" role="alert">欲查詢更完整價格歷程，可利用 <router-link to="/AdvSearch">進階查詢</router-link>，如要查詢逐月相關統計數據，可多加利用 <router-link to="/Stat">查統計</router-link></div>

      <form ref="form" class="row row-cols-lg-2 g-2" @submit.prevent="submit">
        <div class="col-12">
          <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">請選擇</small></label>
          <div class="input-group">
            <div class="input-group-text">
              <i class="bi bi-arrow-left-right"></i>
            </div>
            <select class="form-control form-select input-height" id="select1" v-model="form.selectedItems1" required>
              <!-- <option value="" disabled></option> -->
              <option value="OW">單程</option>
              <option value="RT">來回</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">旅行日期</small></label>
          <SearchFromV3 @input="getD2" v-model="form.dateRange2" :accept="form.selectedItems1"></SearchFromV3>
        </div>
        <div class="col-12">
          <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">出發地</small></label>
          <div class="input-group">
            <div class="input-group-text">
              <font-awesome-icon icon="plane-departure" />
            </div>

            <select class="form-control form-select input-height" name="" v-model="countryName" @change="selectCity" required>
              <option :value="item" v-for="(item, index) in area" :key="index" :selected="selectedDay === item.country">
                {{ item.country }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">目的地</small></label>
          <div class="input-group">
            <div class="input-group-text">
              <font-awesome-icon icon="plane-arrival" />
            </div>
            <select class="form-control form-select input-height" name="" v-model="cityName" @change="setCountryCity" required>
              <option :value="item" v-for="(item, index) in countryName.city" :key="index">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12"></div>
        <div class="col-12">
          <button class="btn btn-primary btn-lg float-end mt-2" type="submit">查票價軌跡</button>
        </div>
      </form>
    </div>
  </div>
</template>
<style>
  .input-height {
    height: 39px;
  }
  .cd1 {
    background-image: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url("../assets/1971537.jpg");
    border-radius: 50px;
  }
</style>

<script>
  // import SearchFromV2 from './SearchFrom_v2.vue';
  import SearchFromV3 from "./SearchFrom_v3.vue"
  import iataCode from "@/mixins/iataCode.js"
  export default {
    name: "VueChart",
    components: {
      // SearchFromV2,
      SearchFromV3,
    },
    mixins: [iataCode],
    data() {
      return {
        countryName: {},
        cityName: "請選擇",
        selectedDay: "台北(桃園)|TPE",
        // city: [],
        // inputContent: 'rrr',
        form: {
          countryName1: [],
          cityName1: [],
          selectedItems1: "OW",
          // selectedItems2: [],
          // selectedItems3: [],
          dateRange: {},
          dateRange2: {},
        },
      }
    },
    methods: {
      getD(val) {
        this.form.dateRange = val
      },
      getD2(val) {
        this.form.dateRange2 = val
      },
      submit() {
        const formData = this.form
        this.$emit("query", formData)
      },
      selectCity(value) {
        const selectedIndex = value.target.options.selectedIndex
        this.form.countryName1 = this.area[selectedIndex].country
      },
      setCountryCity(value) {
        this.form.cityName1 = ""
        const selectedIndex = value.target.options.selectedIndex
        const countryName = this.form.countryName1
        const area = this.area
        area.forEach((e) => {
          if (e.country === countryName) {
            this.form.cityName1 = e.city[selectedIndex]
          }
        })
      },
    },
  }
</script>

<style></style>
