<template>
  <div class="row">
    <div class="col-12">
      <div class="row row-cols-1 mt-5">
        <main class="mt-3 w-75 m-auto"></main>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios"
  export default {
    data: function () {
      return {}
    },
    mounted() {
      this.checkUserAccountVaildCode()
    },
    methods: {
      async checkUserAccountVaildCode() {
        try {
          const me = this
          const query = me.$route.query
          const user_uuid = query.id
          const email_confirm_code = query.code

          axios({
            method: "GET",
            url: `${me.$apiUrl2}checkUserAccountVaildCode/${user_uuid}/${email_confirm_code}`,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
              "Content-Type": "application/json",
            },
          }).then(function (response) {
            const res = response.data

            if (res.status == 201) {
              if (res.statusText == "Update") {
                me.$alert("驗證成功", "系統訊息", "success").then(() => {
                  window.location.href = `/Subscribe?id=${user_uuid}&op=notify_manage`
                })
              }
            }

            if (res.status == 503) {
              if (res.errorCode == "Expired") {
                me.$alert("驗證失敗，由於驗證碼過期", "系統訊息", "error").then(() => {
                  window.location.href = `/`
                })
              }

              if (res.errorCode == "Error") {
                me.$alert("驗證失敗，由於參數錯誤", "系統訊息", "error").then(() => {
                  window.location.href = `/`
                })
              }
            }
          })
        } catch (err) {
          //   this.alerts.push(err)
        }
      },
    },
  }
</script>
<style lang=""></style>
