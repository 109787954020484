<template>
  <div class="card shadow mb-4 bg-white rounded">
    <div class="card-body cd1">
      <form ref="form" class="row row-cols-lg-2 g-2" @submit.prevent="submit">
        <div class="col-12">
          <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">請選擇</small></label>
          <div class="input-group">
            <div class="input-group-text">
              <i class="bi bi-arrow-left-right"></i>
            </div>
            <select class="form-control form-select input-height" id="select1" v-model="form.selectedItems1" required>
              <!-- <option value="" disabled></option> -->
              <option value="OW">單程</option>
              <option value="RT">來回</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">旅行日期</small></label>
          <SearchFromV3 @input="getD2" v-model="form.dateRange2" :accept="form.selectedItems1"></SearchFromV3>
        </div>
        <div class="col-12">
          <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">出發地</small></label>
          <div class="input-group">
            <div class="input-group-text">
              <font-awesome-icon icon="plane-departure" />
            </div>

            <select class="form-control form-select input-height" name="" v-model="countryName" @change="selectCity" required>
              <option :value="item" v-for="(item, index) in area" :key="index" :selected="selectedDay === item.country">
                {{ item.country }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <label for="staticEmail2"><small class="d-inline-flex mb-1 px-2 py-1 fw-semibold text-success bg-success bg-opacity-10 border border-success border-opacity-10 rounded-2">目的地</small></label>
          <div class="input-group">
            <div class="input-group-text">
              <font-awesome-icon icon="plane-arrival" />
            </div>
            <select class="form-control form-select input-height" name="" v-model="cityName" @change="setCountryCity" required>
              <option :value="item" v-for="(item, index) in countryName.city" :key="index">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12"></div>
        <div class="col-12">
          <button class="btn btn-warning btn-lg float-end mt-2" type="submit">2020前查詢</button>
        </div>
      </form>
    </div>
  </div>
</template>
<style>
  .input-height {
    height: 39px;
  }
  .cd1 {
    background-image: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url("../assets/1971537.jpg");
    border-radius: 50px;
  }
</style>

<script>
  // import SearchFromV2 from './SearchFrom_v2.vue';
  import SearchFromV3 from "./SearchFrom2020_v3.vue"
  export default {
    name: "VueAdvSearch",
    components: {
      // SearchFromV2,
      SearchFromV3,
    },

    data() {
      return {
        countryName: {},
        cityName: "請選擇",
        selectedDay: "台北(桃園)|TPE",
        area: [
          {
            country: "台北(桃園)|TPE",
            city: ["札幌|CTS", "旭川|AKJ", "函館|HKD", "秋田|AXT", "花卷|HNA", "仙台|SDJ", "茨城|IBR", "東京成田|NRT", "東京羽田|HND", "新潟|KIJ", "小松|KMQ", "名古屋|NGO", "大阪|KIX", "岡山|OKJ", "高知|KCZ", "福岡|FUK", "佐賀|HSG", "沖繩|OKA"],
            // "-----", "首爾(仁川)|ICN", "釜山|PUS", "大邱|TAE", "濟州|CJU"],
          },
          {
            country: "高雄|KHH",
            city: [
              "東京成田|NRT",
              "大阪|KIX",
              "名古屋|NGO",
              "福岡|FUK",
              // "-----",
              // "首爾(仁川)|ICN",
              // "首爾(金浦)|GMP",
              // "釜山|PUS",
              // "沖繩|OKA"
            ],
          },
          {
            country: "-----",
            city: [],
          },
          {
            country: "札幌|CTS",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "旭川|AKJ",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "函館|HKD",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "旭川|AKJ",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "秋田|AXT",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "花卷|HNA",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "仙台|SDJ",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "茨城|IBR",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "東京成田|NRT",
            city: ["台北(桃園)|TPE", "高雄|KHH"],
          },
          {
            country: "東京羽田|HND",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "新潟|KIJ",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "小松|KMQ",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "名古屋|NGO",
            city: ["台北(桃園)|TPE", "高雄|KHH"],
          },
          {
            country: "大阪|KIX",
            city: ["台北(桃園)|TPE", "高雄|KHH"],
          },
          {
            country: "岡山|OKJ",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "高知|KCZ",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "福岡|FUK",
            city: ["台北(桃園)|TPE", "高雄|KHH"],
          },
          {
            country: "佐賀|HSG",
            city: ["台北(桃園)|TPE"],
          },
          {
            country: "沖繩|OKA",
            city: ["台北(桃園)|TPE"],
          },
          // {
          //   country: "-----",
          //   city: [],
          // },
          // {
          //   country: "首爾(仁川)|ICN",
          //   city: [
          //     "台北(桃園)|TPE",
          //     "高雄|KHH",
          //     // "沖繩|OKA"
          //   ],
          // },
          // {
          //   country: "首爾(金浦)|GMP",
          //   city: [
          //     // "台北(桃園)|TPE",
          //     "高雄|KHH",
          //     // "沖繩|OKA"
          //   ],
          // },
          // {
          //   country: "釜山|PUS",
          //   city: [
          //     "台北(桃園)|TPE",
          //     "高雄|KHH",
          //     // "沖繩|OKA"
          //   ],
          // },
          // {
          //   country: "大邱|TAE",
          //   city: [
          //     "台北(桃園)|TPE",
          //     // "高雄|KHH",
          //     // "沖繩|OKA"
          //   ],
          // },
          // {
          //   country: "濟州|CJU",
          //   city: [
          //     "台北(桃園)|TPE",
          //     // "高雄|KHH",
          //     // "沖繩|OKA"
          //   ],
          // },
        ],
        // city: [],
        // inputContent: 'rrr',
        form: {
          countryName1: [],
          cityName1: [],
          selectedItems1: "OW",
          // selectedItems2: [],
          // selectedItems3: [],
          dateRange: {},
          dateRange2: {},
        },
      }
    },
    methods: {
      getD(val) {
        this.form.dateRange = val
      },
      getD2(val) {
        this.form.dateRange2 = val
      },
      submit() {
        const formData = this.form
        this.$emit("query", formData)
      },
      selectCity(value) {
        const selectedIndex = value.target.options.selectedIndex
        this.form.countryName1 = this.area[selectedIndex].country
      },
      setCountryCity(value) {
        this.form.cityName1 = ""
        const selectedIndex = value.target.options.selectedIndex
        const countryName = this.form.countryName1
        const area = this.area
        area.forEach((e) => {
          if (e.country === countryName) {
            this.form.cityName1 = e.city[selectedIndex]
          }
        })
      },
    },
  }
</script>

<style></style>
