<template>
  <!-- <div class="container-fluid"> -->
  <div class="row row-cols-1">
    <loading :active.sync="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage">
      <template v-slot:before><span class="loadingText">請稍後...</span> </template></loading
    >
    <div class="col-12 mt-5">
      <div class="row row-cols-1 mt-5">
        <main class="form-signin w-auto m-auto">
          <div class="alert alert-danger" role="alert" v-if="alerts.length > 0">
            <!-- {{ alert }} -->
            <ul>
              <li v-for="(alert, key) in alerts" :key="key">{{ alert }}</li>
            </ul>
          </div>
          <div class="shadow p-3 mb-5 bg-body rounded g-light bg-gradient">
            <form @submit.prevent="handlerUserCreate">
              <h3 class="text-center"><i class="bi bi-person-add"></i> 加入 Just.崩潰</h3>
              <p class="text-right">
                已經有帳號了？
                <router-link :to="{ name: 'login' }" class="btn btn-link">點此登入</router-link>
              </p>
              <!-- <div class="mb-3">
                <label>Full Name</label>
                <input type="text" class="form-control form-control-lg" v-model="form.name" />
              </div> -->
              <div class="form-floating mb-3">
                <input type="text" class="form-control form-control-lg" id="floatingInput" v-model="form.email" placeholder="" />
                <label for="floatingInput">E-mail</label>
                <div id="emailHelpBlock" class="form-text">E-mail 會作為本站登入帳號使用</div>
              </div>
              <div class="form-floating mb-3">
                <input type="password" class="form-control form-control-lg" id="floatingInput" v-model="form.password" placeholder="" required />
                <label for="floatingInput">密碼</label>
                <div id="passwordHelpBlock" class="form-text">設定要求: 長度介於 6 ~ 30 字元間，限用英文及數字</div>
              </div>

              <div class="form-floating mb-3">
                <input type="password" class="form-control form-control-lg" id="floatingInput" v-model="form.passwordVerify" placeholder="" required />
                <label for="floatingInput">請再輸入一次密碼</label>
              </div>
              <hr />
              <div class="mb-3">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="invalidCheck" required />
                  <label class="form-check-label" for="invalidCheck"> 我同意網站服務條款及隱私權政策 </label>
                  <!-- <div class="invalid-feedback">You must agree before submitting.</div> -->
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <button type="submit" class="btn btn-dark btn-block btn-lg">註冊帳號</button>
              </div>
            </form>
          </div>
        </main>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<style scoped>
  button,
  input {
    display: block;
    margin-bottom: 10px;
  }

  #alert {
    color: red;
    margin-bottom: 10px;
  }
</style>
<script>
  import AES from "crypto-js/aes"
  import Joi from "joi"
  // import encUtf8 from 'crypto-js/enc-utf8';
  import axios from "axios"
  // import moment from "moment"
  import Loading from "vue-loading-overlay"
  import "vue-loading-overlay/dist/vue-loading.css"

  export default {
    name: "VueNewUser",
    components: {
      Loading,
    },
    mounted() {},
    data: function () {
      return {
        form: {
          email: "",
          // name: "",
          password: "",
          passwordVerify: "",
        },
        alerts: [],
        isLoading: false,
        fullPage: true,
      }
    },
    methods: {
      async handlerUserCreate(e) {
        e.preventDefault()
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        }, 5000)

        this.alerts = []

        const schema = Joi.object({
          password: Joi.string()
            .pattern(new RegExp("^[a-zA-Z0-9]{6,30}$"))
            .error((errors) => {
              errors.forEach((err) => {
                switch (err.code) {
                  case "any.empty":
                    err.message = "密碼1不能為空！"
                    break
                  case "string.pattern.base":
                    err.message = `所輸入的密碼必須符合設定原則(僅限英數且長度介於 6~30 字元間)`
                    break
                  default:
                    break
                }
              })
              return errors
            }),
          repeat_password: Joi.any()
            .valid(Joi.ref("password"))
            .error((errors) => {
              errors.forEach((err) => {
                switch (err.code) {
                  case "any.empty":
                    err.message = "密碼2不能為空！"
                    break
                  case "any.only":
                    err.message = `所輸入的密碼2必須跟密碼1一致`
                    break
                  default:
                    break
                }
              })
              return errors
            }),
          email: Joi.string()
            .email({ minDomainSegments: 2, tlds: { allow: false } })
            .error((errors) => {
              errors.forEach((err) => {
                switch (err.code) {
                  case "string.empty":
                    err.message = "E-mail 不能為空！"
                    break
                  case "string.email":
                    err.message = `所輸入的 E-mail 不符合格式要求`
                    break
                  default:
                    break
                }
              })
              return errors
            }),
        }).with("password", "repeat_password")

        try {
          await schema.validateAsync({ email: this.form.email, password: this.form.password, repeat_password: this.form.passwordVerify })
          const key = process.env.VUE_AES_KEY // change to your key
          // const iv = "sinasinasisinaaa" // change to your iv

          const json_d = {
            email: this.form.email,
            password: AES.encrypt(this.form.password, key).toString(),
            from: "portal",
          }

          const me = this

          axios({
            method: "POST",
            url: `${me.$apiUrl2}addUserAccountByEamil`,
            data: json_d,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
              "Content-Type": "application/json",
            },
          }).then(function (response) {
            const res = response.data
            const user_uuid = res.data.id

            if (res.statusText == "Created1")
              if (res.data.id && res.data.id != "") {
                // me.$fire({
                //   title: "系統訊息",
                //   text: "你好！該 E-mail 目前尚未完成 E-mail 驗證，由於 E-mail 驗證在目前並非必要動作，若有完成驗證可同時提高本站其他服務可用額度，你是否要繼續完成 E-mail 驗證？",
                //   showDenyButton: true,
                //   showCancelButton: false,
                //   confirmButtonText: "開始驗證",
                //   denyButtonText: "下次再說",
                // }).then((result) => {
                //   if (result.isConfirmed) {
                //     const json_d = {
                //       uuid: user_uuid,
                //       from: "portal",
                //     }
                //     axios({
                //       method: "POST",
                //       url: `${me.$apiUrl2}sendUserAccountVaildCode`,
                //       data: json_d,
                //       headers: {
                //         Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                //         "Content-Type": "application/json",
                //       },
                //     }).then(function (response) {
                //       const res = response.data
                //       if (res.status === 201) {
                //         if (res.statusText === "Created" || res.statusText === "Update") {
                //           me.$alert("驗證碼已寄出，還煩請檢查所登錄的 E-mail 信箱", "系統訊息", "success").then(() => {
                //             window.location.href = `/Login`
                //           })
                //         }
                //       }

                //       if (res.status === 201) {
                //         if (res.statusText === "Select") {
                //           me.$alert("已經完成驗證，無須再重複驗證", "系統訊息", "success").then(() => {
                //             // window.location.href = `/Login`
                //           })
                //         }
                //       }

                //       if (res.status === 202) {
                //         if (res.statusText === "Select") {
                //           me.$alert("如仍未收到驗證信，請於一小時候再重新補寄", "系統訊息", "success").then(() => {
                //             // window.location.href = `/Login`
                //           })
                //         }
                //       }
                //     })
                //   } else if (result.isDenied) {
                //     window.location.href = `/Subscribe?id=${user_uuid}&op=notify_manage`
                //   }
                // })
                window.location.href = `/Subscribe?id=${user_uuid}&op=notify_manage`
                return
              }

            if (res.statusText == "Created2")
              if (res.data.id && res.data.id != "") {
                // me.$fire({
                //   title: "系統訊息",
                //   text: "你好！該 E-mail 目前尚未完成 E-mail 驗證，由於 E-mail 驗證在目前並非必要動作，若有完成驗證可同時提高本站其他服務可用額度，你是否要繼續完成 E-mail 驗證？",
                //   showDenyButton: true,
                //   showCancelButton: false,
                //   confirmButtonText: "開始驗證",
                //   denyButtonText: "下次再說",
                // }).then((result) => {
                //   if (result.isConfirmed) {
                //     const json_d = {
                //       uuid: user_uuid,
                //       from: "portal",
                //     }
                //     axios({
                //       method: "POST",
                //       url: `${me.$apiUrl2}sendUserAccountVaildCode`,
                //       data: json_d,
                //       headers: {
                //         Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
                //         "Content-Type": "application/json",
                //       },
                //     }).then(function (response) {
                //       const res = response.data
                //       if (res.status === 201) {
                //         if (res.statusText === "Created" || res.statusText === "Update") {
                //           me.$alert("驗證碼已寄出，還煩請檢查所登錄的 E-mail 信箱", "系統訊息", "success").then(() => {
                //             window.location.href = `/Login`
                //           })
                //         }
                //       }

                //       if (res.status === 201) {
                //         if (res.statusText === "Select") {
                //           me.$alert("已經完成驗證，無須再重複驗證", "系統訊息", "success").then(() => {
                //             // window.location.href = `/Login`
                //           })
                //         }
                //       }

                //       if (res.status === 202) {
                //         if (res.statusText === "Select") {
                //           me.$alert("如仍未收到驗證信，請於一小時候再重新申請補寄", "系統訊息", "success").then(() => {
                //             // window.location.href = `/Login`
                //           })
                //         }
                //       }
                //     })
                //   } else if (result.isDenied) {
                //     window.location.href = `/Subscribe?id=${user_uuid}&op=notify_manage`
                //   }
                // })
                window.location.href = `/Subscribe?id=${user_uuid}&op=notify_manage`
                return
              }

            if (res.statusText == "Select") me.$confirm("註冊不成功，若先前已有註冊但無法登入，請先利用重設密碼功能", "系統訊息", "warning").then(() => {})
          })
        } catch (err) {
          this.alerts.push(err)
        }
      },
    },
    // filters: {
    //   getWeekDay: function (date) {
    //     const weeks = ["", "一", "二", "三", "四", "五", "六", "日"]
    //     return weeks[moment(date).isoWeekday()]
    //   },
    // },
  }
</script>
