<template>
  <main>
    <div class="col-12">
      <nav class="navbar bg-light fixed-top">
        <div class="container-fluid">
          <a class="navbar-brand" href="https://just.bengkuei.me/"><img src="@/assets/jusbklogo.png" width="99" alt="jusbklogo" /></a>
          <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div class="offcanvas-header">
              <h4 class="offcanvas-title" id="offcanvasNavbarLabel">Just.崩潰</h4>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
              <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li class="nav-item">
                  <router-link to="/" class="nav-link"><i class="bi bi-house"></i> 首頁</router-link>
                </li>
                <li class="nav-item" v-if="!main_page_switch">
                  <router-link to="/Login" class="nav-link"><i class="bi bi-door-closed"></i> 登入</router-link>
                </li>
                <li class="nav-item" v-if="!main_page_switch">
                  <router-link to="/NewUser" class="nav-link"><i class="bi bi-person-add"></i> 加入 Just.崩潰</router-link>
                </li>
                <li class="nav-item" v-if="main_page_switch">
                  <router-link to="/Logout" class="nav-link"><i class="bi bi-door-open"></i> 登出</router-link>
                </li>
                <li class="nav-item" v-if="main_page_switch">
                  <router-link :to="'Subscribe?id=' + user_uuid + '&op=notify_manage'" class="nav-link"><i class="bi bi-bell"></i> 票價追蹤</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/AdvSearch" class="nav-link"><i class="bi bi-search"></i> 進階查詢</router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/Stat" class="nav-link"><i class="bi bi-file-spreadsheet"></i> 查統計</router-link>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 關於本站 </a>
                  <ul class="dropdown-menu">
                    <li>
                      <router-link to="/PrivacyPolicy" class="dropdown-item icon-link">隱私權政策</router-link>
                    </li>
                    <li>
                      <router-link to="/TermsOfService" class="dropdown-item icon-link">網站服務條款</router-link>
                    </li>
                    <!-- <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </li> -->
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> 聯絡我們 </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item icon-link" href="mailto:justbk.1234@gmail.com"><i class="bi bi-envelope"></i> 電子郵件</a>
                    </li>
                    <li>
                      <a class="dropdown-item icon-link" href="https://facebook.com/just.bk.me" target="_blank"><i class="bi bi-facebook"></i> 臉書</a>
                    </li>
                    <!-- <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </li> -->
                  </ul>
                </li>
              </ul>
              <!-- <form class="d-flex mt-3" role="search">
                <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                <button class="btn btn-outline-success" type="submit">Search</button>
              </form> -->
            </div>
          </div>
        </div>
      </nav>
    </div>
    <router-view></router-view>
  </main>
</template>
<script>
  import Cookies from "js-cookie"
  import axios from "axios"

  export default {
    data: function () {
      return {
        main_page_switch: false,
        user_uuid: "",
      }
    },
    mounted() {
      if (this.$route.name !== "checkEmailConfirmCode" || this.$route.name !== "resetPwd")
        if (Cookies.get("login_token")) {
          const login_token = Cookies.get("login_token")
          this.checkLoginTokeVaild(login_token)
          this.main_page_switch = true
        }
    },
    methods: {
      checkLoginTokeVaild(login_token) {
        const me = this
        const json_d = { token: login_token }

        axios({
          method: "POST",
          url: `${me.$apiUrl2}checkLoginTokeVaild`,
          data: json_d,
          headers: {
            Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            "Content-Type": "application/json",
          },
        }).then(function (response) {
          const res = response.data

          if (res.status === 201) {
            if (res.statusText === "Select") {
              me.user_uuid = res.data.id
              if (me.$route.name === "login") {
                window.location.href = `/Subscribe?id=${res.data.id}&op=notify_manage`
              }
            }
          }
          if (res.status === 503) {
            me.main_page_switch = false
            me.user_uuid = null
            Cookies.remove("login_token")
            // window.location.href = `/`
          }
        })
      },
    },
  }
</script>
