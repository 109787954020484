<template>
  <div class="row">
    <div class="col-12">
      <div class="row row-cols-1 mt-5">
        <main class="mt-3 w-75 m-auto">
          <blockquote>
            <p>「Just.崩潰」係依據本服務條款提供Just.崩潰網站（<a href="https://just.bengkuei.me">https://just.bengkuei.me</a>，以下簡稱本站）服務。</p>

            <p>當您使用本站時，即表示您已閱讀、瞭解並同意接受本約定書之所有內容。本站有權於任何時間修改或變更本約定書之內容，建議您隨時注意該等修改或變更。您於任何修改或變更後繼續使用本站服務，視為您已閱讀、瞭解並同意接受該等修改或變更。</p>

            <p>若您為未滿二十歲，除應符合上述規定外，並應於您的家長（或監護人）閱讀、瞭解並同意本約定書之所有內容及其後修改變更後，方得使用或繼續使用本站。當您使用或繼續使用本站服務時，即表示您的家長（或監護人）已閱讀、瞭解並同意接受本約定書之所有內容及其後修改變更。</p>

            <p>
              <strong><h4>一、您的註冊義務</h4></strong>
            </p>
            <p>為了能使用本服務，您同意以下事項：<br />依本服務註冊頁面之提示提供您本人正確、最新及完整的資料。維持並更新您個人資料，確保其為正確、最新及完整。若您提供任何錯誤或不實的資料，本站有權暫停或終止您的帳號，並拒絕您使用本服務之全部或一部。</p>

            <p>
              <strong><h4>二、隱私權政策</h4></strong>
            </p>
            <p>關於您的會員註冊以及其他特定資料依本站「隱私權保護政策」受到保護與規範。</p>

            <p>
              <strong><h4>三、會員帳號、密碼及安全</h4></strong>
            </p>
            <p>完成本服務的註冊程序之後，您將擁有一個密碼及帳號。您有責任維持密碼及帳號的機密安全。</p>
            <p>利用該密碼及帳號所進行的一切行為，您將負完全的責任。您並同意以下事項：您的密碼或帳號遭到盜用或有其他任何安全問題發生時，您可立即以電話或電子郵件通知本站。<br />每次連線完畢，均結束您的帳號使用。</p>

            <p>
              <strong><h4>四、使用者的守法義務及承諾</h4></strong>
            </p>
            <p>您承諾絕不為任何非法目的或以任何非法方式使用本站，並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。</p>
            <p>您若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。</p>
            <p>您同意並保證不得利用本服務從事侵害他人權益或違法之行為，包括但不限於：</p>
            <ul>
              <li>公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案。</li>
              <li>侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。</li>
              <li>違反依法律或契約所應負之保密義務。</li>
              <li>冒用他人名義使用本服務。</li>
              <li>傳輸或散佈電腦病毒濫發廣告郵件。</li>
              <li>其他本站有正當理由認為不適當之行為，本站得依實際執行情形，增加、修改或終止相關活動，並選擇最適方式告知會員，會員即應同意並配合之，否則本站有權自行為任何適當之處置措施。</li>
            </ul>
            <p>發生下列情形之一時，本站有權可以停止、中斷提供服務，且不負賠償責任：</p>
            <ul>
              <li>本站網站電子通信設備進行必要之保養及施工時；</li>
              <li>發生突發性之電子通信設備故障時；</li>
              <li>本站申請之電子通信服務被停止，無法提供服務時；</li>
              <li>由於天災等不可抗力之因素致使本站無法提供服務時。</li>
            </ul>
            <p>
              <strong><h4>五、智慧財產權的保護</h4></strong>
            </p>
            <p>本站所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由本站或其他權利人擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載前述軟體、程式或網站內容，必須取得本站或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，您應對本站負損害賠償責任（包括但不限於訴訟費用及律師費用等）。</p>

            <p>
              <strong><h4>六、您對本站之授權</h4></strong>
            </p>
            <p>對於會員所登錄或留存之個人資料，會員同意本站網站得於合理之範圍內蒐集、處理、保存、傳遞及使用該等資料，以提供使用者其他資訊或服務、或作成會員統計資料、或進行關於網路行為之調查或研究，或為任何之合法使用。</p>

            <p>若您無合法權利得授權他人使用、修改、重製、公開播送、改作、散布、發行、公開發表某資料，並將前述權利轉授權第三人，請勿擅自將該資料上載、傳送、輸入或提供至本站。任何資料一經您上載、傳送、輸入或提供至本站時，視為您已允許本站無條件使用、修改、重製、公開播送、改作、散布、發行、公開發表該等資料，並得將前述權利轉授權他人，您對此絕無異議。您並應保證於本站所使用、修改、重製、公開播送、改作、散布、發行、公開發表、轉授權該等資料，不致侵害任何第三人之智慧財產權，否則應對本站負損害賠償責任（包括但不限於訴訟費用及律師費用等）。</p>

            <p>
              <strong><h4>七、拒絕或終止您的使用</h4></strong>
            </p>
            <p>您同意本站得基於維護交易安全之考量，因任何理由，包含但不限於過久未使用或使用頻率過低，或違反本服務條款的明文規定及精神，終止您的密碼、帳號（或其任何部分）或本服務之使用，並將本服務內任何「會員內容」加以移除並刪除，亦得在已電話或電子郵件等方式通知之情形下，隨時終止本服務或任何部分。此外，您同意若本服務之使用被終止，本站對您或任何第三人均不承擔任何責任。</p>

            <p>
              <strong><h4>八、準據法與管轄法院</h4></strong>
            </p>
            <p>本約定書之解釋與適用，以及與本約定書有關的爭議，均應依照中華民國法律予以處理。並由臺灣臺南地方法院作為第一審管轄法院。</p>
          </blockquote>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
  export default {}
</script>
<style lang=""></style>
