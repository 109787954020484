<template>
  <div class="row">
    <div class="col-12">
      <div class="row row-cols-1 mt-5">
        <main class="mt-3 w-75 m-auto">
          <div class="card">
            <div class="card-body">
              <form class="row g-3" @submit.prevent="getFormValues">
                <div class="col-lg-6 col-xs-12">
                  <label for="formGroupExampleInput" class="form-label">幫群組取個名稱</label>
                  <input type="text" class="form-control" id="formGroupExampleInput" placeholder="我的頻道" v-model="notifyform.channel_name" required />
                </div>
                <div class="col-12">
                  <span id="notifychannelHelpInline" class="form-text"> 方便記憶不會搞混就好，不見得要跟自己 LINE群組上名稱一致 </span>
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="is_notify_midnight" :true-value="true" :false-value="false" v-model="notifyform.is_notify_midnight" />
                    <label class="form-check-label" for="is_notify_midnight"> 接受半夜*通知 </label>
                  </div>

                  <div class="col-12">
                    <span id="notifychannelHelpInline1" class="form-text">
                      <ul>
                        <li>半夜定義: 台灣時間 00:00 ~ 01:00，期間僅發送一次為原則</li>
                        <li>
                          預設 <code><B class="fs-5">不開啟</B></code
                          >，可視需要自行勾選（也可於事後勾選），勾選後也請告知該群組內其他成員，以避免造成群組內成員半夜休息時困擾
                        </li>
                      </ul>
                    </span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="is_admin_recommend" v-model="notifyform.is_admin_recommend" checked disabled />
                    <label class="form-check-label" for="is_admin_recommend"> 接受站方資訊推薦* </label>
                  </div>
                  <div class="col-12">
                    <span id="notifychannelHelpInline2" class="form-text">
                      <ul>
                        <li>該選項於群組新建立後須綁定一週，一週後若需要可自行取消 (以系統顯示之到期時間為準)</li>
                        <li>
                          資訊推薦種類 <code><B>(包括但不限於)</B></code>
                          <ul>
                            <li>夏冬季班表開放訂位</li>
                            <li>特殊資訊（票價、漲降情況或其他）</li>
                          </ul>
                        </li>
                      </ul>
                    </span>
                  </div>
                </div>
                <div class="col-12 text-end">
                  <button type="submit" class="btn btn-primary mb-3">設定名稱</button>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "axios"
  export default {
    data() {
      return {
        resp: null,
        config: {
          channel_id: process.env.VUE_LINE_CHANNEL_ID,
          channel_secret: process.env.VUE_LINE_CHANNEL_SECRET,

          // channel_id: "1551570216",
          // channel_secret: "40f2b770fcab54b667e1d37a41c38a40",
        },
        access_token: "",
        notify_access_token: "",
        notifyform: {
          channel_name: "我的頻道",
          email_uuid: "",
          notify_access_token: "",
          is_notify_midnight: false,
          is_admin_recommend: true,
        },
        // apiUrl: "https://justbk-api-4cqv3jfjdq-de.a.run.app/",
        // apiUrl: "http://localhost:8011/",
      }
    },

    created() {
      if (this.$route.query.code && this.$route.query.state) {
        this.notifyform.email_uuid = this.$route.query.state
        this.getLineNotifyToken()
      }
    },
    methods: {
      // async sendNotify(code) {
      //   const params = {
      //     code: `${code}`,
      //     msg: "你好!!hello",
      //   }

      //   const resp = await axios({
      //     url: `${this.$apiUrl2}send/linenotify`,
      //     method: "post",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     data: params,
      //   })
      //   if (resp.status === 200) {
      //     this.resp = resp
      //     // console.log("notify" + resp)
      //   }
      // },
      async getFormValues(e) {
        e.preventDefault()

        const me = this

        await axios({
          method: "PATCH",
          url: `${me.$apiUrl2}addNotifyChannel`,
          headers: {
            Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            "Content-Type": "application/json",
          },
          data: {
            notifyform: me.notifyform,
          },
        }).then(function (response) {
          console.log(response.data)
          const res = response.data
          if (res.status === 503) {
            me.$alert("新增/修改失敗", "系統訊息", "error").then(() => {
              window.location.href = `/`
            })
          }

          if (res.status === 201) {
            if (res.statusText === "Created") {
              me.$alert("新增成功，也請記得也要同時綁定規則唷！這樣符合追蹤條件的結果也才會傳送到你所指定的 LINE 群組當中", "系統訊息", "success").then(() => {
                window.location.href = `/Subscribe?id=${me.$route.query.state}&op=notify_manage`
              })
            }
            if (res.statusText === "Updated") {
              me.$alert("修改成功", "系統訊息", "warning").then(() => {
                window.location.href = `/Subscribe?id=${me.$route.query.state}&op=notify_manage`
              })
            }
          }
        })
      },
      async getLineNotifyToken() {
        const params = {
          code: this.$route.query.code,
        }

        const resp = await axios({
          url: `${this.$apiUrl2}get/linenotify_token`,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          data: params,
        })

        if (resp.status === 200) {
          // console.log(resp.data)
          this.resp = resp.data
          this.notify_access_token = resp.data.data.access_token
          this.notifyform.notify_access_token = resp.data.data.access_token
          // console.log("notify" + resp.data.data.access_token)

          // this.$router.push({ path: "/Subscribe", query: { id: this.$route.query.state, op: "notify_manage" } })
        }
      },
    },
  }
</script>
<style></style>
