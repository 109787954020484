<template>
  <div class="row">
    <div class="col-12">
      <div class="row row-cols-1 mt-5">
        <main class="mt-3 w-auto m-auto">
          <div class="alert alert-danger" role="alert" v-if="alerts.length > 0">
            <!-- {{ alert }} -->
            <ul>
              <li v-for="(alert, key) in alerts" :key="key">{{ alert }}</li>
            </ul>
          </div>
          <div class="shadow p-3 mb-5 bg-body rounded g-light bg-gradient">
            <form @submit.prevent="resetPassword">
              <h3>密碼重設</h3>
              <!-- <p class="text-right">
                已經有帳號了？
                <router-link :to="{ name: 'login' }" class="btn btn-link">點此登入</router-link>
              </p> -->
              <!-- <div class="mb-3">
                <label>Full Name</label>
                <input type="text" class="form-control form-control-lg" v-model="form.name" />
              </div> -->
              <!-- <div class="form-floating mb-3">
                <input type="text" class="form-control form-control-lg" id="floatingInput" v-model="form.email" placeholder="" />
                <label for="floatingInput">E-mail</label>
              </div> -->
              <div class="form-floating mb-3">
                <input type="password" class="form-control form-control-lg" id="floatingInput" v-model="form.password" placeholder="" required />
                <label for="floatingInput">密碼</label>
                <div id="passwordHelpBlock" class="form-text">密碼設定原則: 長度介於 6 ~ 30 字元間，且限用英文及數字</div>
              </div>

              <div class="form-floating mb-3">
                <input type="password" class="form-control form-control-lg" id="floatingInput" v-model="form.passwordVerify" placeholder="" required />
                <label for="floatingInput">再輸入一次密碼</label>
              </div>
              <button type="submit" class="btn btn-dark btn-block btn-lg">更新密碼</button>
            </form>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
  import AES from "crypto-js/aes"
  import Joi from "joi"
  // import encUtf8 from 'crypto-js/enc-utf8';
  import axios from "axios"
  export default {
    data: function () {
      return {
        form: {
          id: "",
          code: "",
          password: "",
          passwordVerify: "",
        },
        alerts: [],
      }
    },
    mounted() {
      //   this.checkUserAccountVaildCode()
      this.form.id = this.$route.query.id
      this.form.code = this.$route.query.code
    },
    methods: {
      async resetPassword(e) {
        e.preventDefault()
        const schema = Joi.object({
          password: Joi.string()
            .pattern(new RegExp("^[a-zA-Z0-9]{6,30}$"))
            .error((errors) => {
              errors.forEach((err) => {
                switch (err.code) {
                  case "any.empty":
                    err.message = "密碼1不能為空！"
                    break
                  case "string.pattern.base":
                    err.message = `所輸入的密碼必須符合設定原則(僅限英數且長度介於 6~30 字元間)`
                    break
                  default:
                    break
                }
              })
              return errors
            }),
          repeat_password: Joi.any()
            .valid(Joi.ref("password"))
            .error((errors) => {
              errors.forEach((err) => {
                switch (err.code) {
                  case "any.empty":
                    err.message = "密碼2不能為空！"
                    break
                  case "any.only":
                    err.message = `所輸入的密碼2必須跟密碼1一致`
                    break
                  default:
                    break
                }
              })
              return errors
            }),
        }).with("password", "repeat_password")

        try {
          await schema.validateAsync({ password: this.form.password, repeat_password: this.form.passwordVerify })
          const key = process.env.VUE_AES_KEY // change to your key
          // const iv = "sinasinasisinaaa" // change to your iv
          //   const now = moment()
          const json_d = {
            password: AES.encrypt(this.form.password, key).toString(),
            // v: AES.encrypt(now).toString(),
            id: this.form.id,
            code: this.form.code,
            // from: "portal",
          }

          const me = this

          axios({
            method: "POST",
            url: `${me.$apiUrl2}resetPasssword`,
            data: json_d,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
              "Content-Type": "application/json",
            },
          }).then(function (response) {
            const res = response.data
            // const res = d.data
            if (res.status === 201) {
              if (res.statusText === "Update") {
                me.$alert("密碼變更成功，請使用新密碼重新登入", "系統訊息", "success").then(() => {
                  window.location.href = `/Login`
                })
              }

              if (res.statusText === "Select") {
                me.$alert("密碼變更失敗，請重新寄發通知信", "系統訊息", "error").then(() => {
                  window.location.href = `/Login`
                })
              }
            }

            if (res.status === 503) {
              if (res.errorCode === "Error") {
                me.$alert("密碼變更失敗", "系統訊息", "error").then(() => {
                  window.location.href = `/Login`
                })
              }
            }
          })
        } catch (err) {
          this.alerts.push(err)
        }
      },
    },
  }
</script>
<style lang=""></style>
