<template>
  <div class="container-fluid">
    <div class="row row-cols-1">
      <loading :active.sync="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage">
        <template v-slot:before><span class="loadingText">每一個票價在當下都代表一個意義...</span> </template></loading
      >
      <div class="col-12">
        <div class="row row-cols-1 mt-5">
          <div class="col">
            <div class="card border border-0">
              <div class="card-body">
                <SearchFrom @query="initChart"></SearchFrom>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-cols-1" v-if="!showSection">
          <div class="col">
            <div class="card border border-0">
              <div class="card-body">
                <!-- <router-view></router-view> -->
                <!-- <SponsorTable></SponsorTable> -->
                <!-- <MonthlyTable></MonthlyTable> -->
                <!-- <CompareMonthlyTable></CompareMonthlyTable> -->
                <!-- <LazyBag></LazyBag> -->
                <!-- <LowFare></LowFare> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col" v-if="!showSection">
          <div class="alert alert-warning" role="alert">
            <ol class="m-0">
              <li class="mb-1">所提供的票價資訊均是建立在「直飛，台灣出發的來回且大人人數是 1 位」的情況下取得</li>
              <li class="mb-1">除非有另外說明，不然票價單位一律都是「單程未稅價，新台幣(TWD)」，票種也一律都是基本票種（也就是不包含任何附加服務項目）</li>
              <li class="mb-1">票價軌跡呈現以提供最近 10 筆為主(未來視情況調整)</li>
              <li class="mb-1">部份業者在外站出發定價上，當地貨幣對新台幣轉換上不一定會是等值，還請留意</li>
              <li class="mb-1">本站將盡力提供正確之資訊，惟本網站對於所載資料之完整性、即時性和正確性不做任何擔保</li>
            </ol>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-lg-1 bg-success p-2 text-dark bg-opacity-10 rounded-2" v-show="showSection">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <div id="ch1">
                  <apexchart ref="initChart" type="line" height="360" :options="chartOptions" :series="series"></apexchart>
                </div>
                <div id="ch2">
                  <apexchart ref="initChart2" type="line" height="100" :options="chartOptionsLine" :series="series"></apexchart>
                </div>
                <!-- <div id="ch3">
                  <apexchart ref="chart" width="100%" height="350px" :options="options2" :series="options2.series"> </apexchart>
                </div> -->
              </div>
            </div>
          </div>
          <!-- <hr class="hrs" /> -->
          <div class="col mt-3" v-if="q.trip_type === 'RT'">
            <div class="card">
              <div class="card-body">
                <div id="ch4">
                  <apexchart ref="initChart3" type="line" height="600" :options="chartOptions" :series="series"> </apexchart>
                </div>
                <div id="ch5">
                  <apexchart ref="initChart4" type="line" height="300" :options="chartOptionsLine" :series="series"> </apexchart>
                </div>
                <!-- <div id="ch6">
                  <apexchart ref="chart2" width="100%" height="350px" :options="options5" :series="options5.series"> </apexchart>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
  @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
  /* @import url("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@6.3.0/css/fontawesome.min.css"); */
</style>
<style lang="css" src="@/css/a.css" scoped></style>
<script>
  import SearchFrom from "./SearchFrom2020.vue"
  import axios from "axios"
  import Loading from "vue-loading-overlay"
  import "vue-loading-overlay/dist/vue-loading.css"
  export default {
    name: "VueChart",
    components: {
      // LazyBag: () => import("./LazyBag.vue"),
      // SponsorTable: () => import("./SponsorTable.vue"),
      // LowFare: () => import("./LowFare.vue"),
      SearchFrom,
      Loading,
      // MonthlyTable: () => import("./MonthlyTable.vue"),
      // CompareMonthlyTable: () => import("./CompareMonthlyTable.vue"),
    },
    mounted: async function () {
      this.doAjax()
    },
    data: function () {
      return {
        isLoading: false,
        fullPage: true,
        showCreateSection: false,
        q: {
          dept: "",
          arri: "",
          trip_type: "",
        },
        rows: [],
        rows2: [],
        seen4: false,
        seen3: false,
        // seen2: false,
        seen: false,
        series: [],
        series3: [],
        series_res: [],
        series_res2: [],
        apiUrl: "https://justbk-api-4cqv3jfjdq-de.a.run.app/",
        // apiUrl: "http://localhost:8000/",

        chartOptionsLine: {
          chart: {
            id: "chart1",
            height: 100,
            type: "line",
            brush: {
              target: "chart2",
              enabled: true,
            },
            stroke: {
              curve: "straight",
            },
            selection: {
              enabled: true,
              xaxis: {
                min: new Date("2016-02-15").getTime(),
                max: new Date("2016-02-18").getTime(),
              },
            },
          },
          colors: ["#008FFB"],
          xaxis: {
            type: "datetime",
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            // tickAmount: 2,
          },
        },
        chartOptions: {
          chart: {
            id: "chart2",
            height: 360,
            type: "bar",
            dropShadow: {
              enabled: true,
              color: "#000",
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            // toolbar: {
            //   show: false,
            // },
            zoom: {
              type: "x",
              enabled: true,
              autoScaleYaxis: true,
            },
          },
          noData: {
            text: "Loading...",
          },
          responsive: [
            {
              breakpoint: 1000,
              options: {
                title: {
                  style: {
                    fontSize: "12px",
                  },
                },
              },
            },
          ],
          colors: ["#77B6EA", "#545454"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          title: {
            // text: "",
            align: "left",
          },
          grid: {
            borderColor: "#e7e7e7",
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          markers: {
            size: 1,
          },
          xaxis: {
            type: "datetime",
            title: {
              text: "紀錄日期",
              // offsetY: 75,
            },
          },
          yaxis: {
            title: {
              text: "票價(單程未稅)",
            },
            // min: 5,
            // max: 40,
          },
          legend: {
            // position: "top",
            // horizontalAlign: "right",
            // floating: true,
            itemMargin: {
              vertical: 15,
            },
            // offsetY: -25,
            // offsetX: -5,
          },
        },
      }
    },
    computed: {
      showSection() {
        return this.showCreateSection
      },
    },
    methods: {
      doAjax() {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        }, 4500)
      },
      onCancel() {},
      initChart(val) {
        this.showCreateSection = true
        this.seen3 = false
        const query_d = val.dateRange2
        const dept = val.countryName1.split("|")[1]
        const arri = val.cityName1.split("|")[1]
        const trip_type = val.selectedItems1

        const me = this
        me.q.dept = dept
        me.q.arri = arri
        me.q.trip_type = trip_type

        me.rows = []
        me.rows2 = []
        let qurl = `${this.apiUrl}get2020historyfare2/`,
          // let qurl = `${this.apiUrl}advstat/`,
          dept_d,
          arri_d

        if (trip_type === "OW") {
          dept_d = query_d
          arri_d = query_d
          axios({
            method: "GET",
            url: `${qurl}${dept_d}/${dept}/${arri}`,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            },
          }).then((response) => {
            me.rows = response.data

            me.series_res = response.data[0]
            // me.series_res2 = response.data[1]

            // let res1 = []
            // res2 = [],
            // res3 = [],
            // res4 = [],
            // detpt_f
            // arri_f
            // detpt_f = me.series_res[0][0][0][0].flightNo
            // if (trip_type === "RT") {
            //   arri_f = me.series_res2[0][0][0][0].flightNo
            // }

            // console.log(response.data)

            // for (let i = 0; i < response.data.length; i++) {
            //   const e = response.data[i]
            //   for (let j = 0; j < e[0].x.length; j++) {
            //     let json_obj1 = {
            //       name: e[0].flightNo,
            //       type: "bar",
            //       data: { x: e[0].x[j], y: e[0].y[j] },
            //     }
            //     res1.push(json_obj1)
            //   }

            //   // let json_obj2 = {
            //   //   name: e[0][0][0].flightNo,
            //   //   type: "bar",
            //   //   data: e[0][1],
            //   // }
            //   // res2.push(json_obj2)
            // }
            // console.log(res1)
            me.$refs.initChart.updateSeries(me.series_res)
            me.$refs.initChart2.updateSeries(me.series_res)
            // me.updateChart(dept_d, detpt_f, "chart")
            // me.$refs.initChart.removeAnnotation("my-annotation1")

            me.chartOptions = {
              ...me.chartOptions,
              ...{
                // labels: response.data[0][1],
                colors: response.data[2],
                legend: {
                  itemMargin: {
                    vertical: 15,
                  },
                },
                title: {
                  text: `${dept} 飛往 ${arri} 歷史價格紀錄 (2016年)`,
                  style: {
                    fontSize: "20px",
                  },
                },
                // subtitle: {
                //   text: "所提供的票價資訊均是建立在「台灣出發的來回且大人人數是 1 位」的情況",
                // },
              },
            }

            me.chartOptionsLine = {
              ...me.chartOptionsLine,
              ...{
                colors: response.data[2],
                type: "line",
                chart: {
                  selection: {
                    xaxis: {
                      min: new Date(response.data[1].start).getTime(),
                      max: new Date(response.data[1].end).getTime(),
                    },
                  },
                },
              },
            }
            // console.log(me.chartOptionsLine)
            me.$refs.initChart2.updateOptions(me.chartOptions)
            me.$refs.initChart2.updateOptions(me.chartOptionsLine)
          })
        }

        if (trip_type === "RT") {
          dept_d = query_d[0]
          arri_d = query_d[1]
          axios({
            method: "GET",
            url: `${qurl}${dept_d}/${dept}/${arri}`,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            },
          }).then((response) => {
            me.rows = response.data
          })

          axios({
            method: "GET",
            url: `${qurl}${arri_d}/${arri}/${dept}`,
            headers: {
              Authorization: `Bearer 76s6hBdR594llBaEBNtP`,
            },
          }).then((response) => {
            me.rows2 = response.data
          })
        }
      },
    },
  }
</script>

<style></style>
